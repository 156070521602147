import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

export type SearchText = string | null;

export interface SearchInputFieldProps {
    value: SearchText;
    onChange: (newValue: string | null) => void;
    style?: React.CSSProperties;
    className?: string;
    tooltip: string | JSX.Element;
}

export const SearchInputField = styled(SearchInputFieldComponent, {
    name: 'SearchInputField',
    slot: 'Root',
    overridesResolver: (props, styles) => [
        styles.root,
        props.color === 'primary' && styles.primary,
        props.color === 'secondary' && styles.secondary,
    ],
})(({ theme }) => ({
    margin: theme.spacing(0, 1, 0, 0),
}));

function SearchInputFieldComponent({
    value,
    style,
    onChange,
    className, // passed from styleed
    tooltip,
}: SearchInputFieldProps) {
    const theme = useTheme();

    return (
        <Tooltip title={tooltip} placement="bottom" arrow disableFocusListener>
            <TextField
                value={value || ''}
                onChange={evt => onChange(evt.target.value)}
                style={style}
                sx={{
                    color: theme.palette.common?.white,
                }}
                variant="filled"
                hiddenLabel={true}
                className={className}
                color="primary"
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{
                                    visibility: value ? 'visible' : 'hidden',
                                    color: theme.palette.white.main,
                                    '& :hover': {
                                        color: theme.palette.blue.electric,
                                    },
                                }}
                                onClick={() => onChange(null)}
                                edge="end"
                                size="large"
                            >
                                <Clear />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        backgroundColor: value
                            ? theme.palette.blue.main
                            : undefined,
                        color: 'inherit',
                    },
                    inputProps: {
                        style: {
                            paddingTop: theme.spacing(0.75),
                            paddingBottom: theme.spacing(0.75),
                        },
                    },
                }}
                autoFocus
            />
        </Tooltip>
    );
}
