import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { FinishedTransactionsList } from './FinishedTransactionsList.tsx';
import {
    Body,
    Container,
    ModalSpecialTabResultRefresher,
    TabItem,
    Tabs,
} from '../../ui/layout/TabContent.tsx';
import * as Text from '../../common/i18n/Text.ts';
import { parkTransactionsTexts } from '../i18n/ParkTransactionsTexts.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as TomState from '../../tom/state/TomState.ts';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { FilterHalfSlideIn } from './FilterHalfSlideIn.tsx';
import { DateFilterHalfSlideIn } from './DateFilter.tsx';
import { TomFilterTransactionListSlideIn } from './TomFilterTransactionListSlideIn.tsx';
import { ParkTransactionsFilterSummary } from './ParkTransactionsFilterSummary.tsx';
import { TransactionDetailSlideIn } from './TomTransactionDetailSlideIn.tsx';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../../ui/filter/FilterLayout.tsx';
import { PrimaryLinkButton } from '../../ui/buttons/TextButton.tsx';
import * as Http from '../../api/Http.ts';
import * as Url from 'dg-web-shared/lib/Url.ts';
import { StatisticsMovedInfo } from '../../statistics/StatisticsMovedInfo.tsx';

export interface ParkTransactionsTexts {
    tabtitleFinishedTransactions: Text.Translation;
    tabtitleTransactionStatistics: Text.Translation;
    ExportTitle: Text.Translation;
}

interface ParkTransactionsState {
    settings: SettingsState.State;
    completedParkTransactions: ParkTransactionsState.List.State;
    parkTransactionsLayout: ParkTransactionsState.Layout.State;
    detail: ParkTransactionsState.Detail.State;
    filter: ParkTransactionsState.Filter.State;
    toms: TomState.List.State;
}

export class ParkTransactionsOverview extends Flux.Container<ParkTransactionsState> {
    stateSelector(): ParkTransactionsState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            completedParkTransactions: ParkTransactionsState.List.Completed.get(
                this.props.allState,
            ),
            parkTransactionsLayout: ParkTransactionsState.Layout.get(
                this.props.allState,
            ),
            detail: ParkTransactionsState.Detail.get(this.props.allState),
            filter: ParkTransactionsState.Filter.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
        };
    }

    renderTransactions(): JSX.Element {
        const texts = parkTransactionsTexts[this.state.settings.language];
        const filter = this.state.filter;
        const exportDisabled =
            !ParkTransactionsState.Filter.filterActive(filter);
        const params = Http.OperatorAccount.TomParkTransactions.buildParams({
            validFrom: filter.validFrom,
            validTo: filter.validTo,
            toms: filter.toms,
            lang: this.state.settings.language,
        });
        const exportUrl = `/ui-api/operator-account/taxomex/park-transactions/csv-export?${Url.encodeData(
            params,
        )}`;

        return (
            <FilterLayout>
                <FilterLayoutHeader
                    filterSummary={
                        <ParkTransactionsFilterSummary
                            allState={this.props.allState}
                        />
                    }
                    rightDropdown={
                        <PrimaryLinkButton
                            href={exportUrl}
                            label={texts.ExportTitle()}
                            disabled={exportDisabled}
                        />
                    }
                />
                <FilterLayoutBody>
                    <FinishedTransactionsList allState={this.props.allState} />
                </FilterLayoutBody>
            </FilterLayout>
        );
    }

    renderTransactionStatistics(): JSX.Element {
        return <StatisticsMovedInfo />;
    }

    renderTabBody(): JSX.Element | null {
        switch (this.state.parkTransactionsLayout.selectedTab) {
            case ParkTransactionsState.SelectedTab.Transactions:
                return this.renderTransactions();
            case ParkTransactionsState.SelectedTab.TransactionStatistics:
                return this.renderTransactionStatistics();
            default:
                return null;
        }
    }

    renderSlideIns(): JSX.Element[] | null {
        switch (this.state.parkTransactionsLayout.selectedTab) {
            case ParkTransactionsState.SelectedTab.Transactions:
                return [
                    <FilterHalfSlideIn
                        allState={this.props.allState}
                        key="filter"
                    />,
                    <DateFilterHalfSlideIn
                        allState={this.props.allState}
                        key="dateFilter"
                    />,
                    <TomFilterTransactionListSlideIn
                        allState={this.props.allState}
                        key="listTomFilter"
                    />,
                    <TransactionDetailSlideIn key="transactionDetail" />,
                ];
            case ParkTransactionsState.SelectedTab.TransactionStatistics:
            default:
                return null;
        }
    }

    getDependentStateSlices(): AbstractLegacyServerState<unknown>[] {
        const globalLoaders: AbstractLegacyServerState<unknown>[] = [
            this.state.toms,
        ];
        switch (this.state.parkTransactionsLayout.selectedTab) {
            case ParkTransactionsState.SelectedTab.Transactions:
                return globalLoaders.concat([
                    this.state.completedParkTransactions,
                ]);
            case ParkTransactionsState.SelectedTab.TransactionStatistics:
            default:
                return globalLoaders;
        }
    }

    isBodyDisabled(): boolean {
        const layout = this.state.parkTransactionsLayout;

        switch (layout.selectedTab) {
            case ParkTransactionsState.SelectedTab.Transactions:
                return (
                    isDefined(this.state.detail.selectedTransaction) ||
                    this.state.filter.filterSlideInVisible
                );
            case ParkTransactionsState.SelectedTab.TransactionStatistics:
            default:
                return false;
        }
    }

    render() {
        const texts = parkTransactionsTexts[this.state.settings.language];
        const layout = this.state.parkTransactionsLayout;

        return (
            <Container>
                <Tabs>
                    <TabItem
                        active={
                            layout.selectedTab ===
                            ParkTransactionsState.SelectedTab.Transactions
                        }
                        description={texts.tabtitleFinishedTransactions()}
                        rightText={
                            ParkTransactionsState.Filter.filterActive(
                                this.state.filter,
                            ) &&
                            !this.state.completedParkTransactions.pending &&
                            this.state.completedParkTransactions.statusCode.ok
                                ? this.state.completedParkTransactions.data.size.toString()
                                : ''
                        }
                        onClick={() =>
                            this.update(store =>
                                ParkTransactionsState.Layout.stateWrite(store, {
                                    selectedTab:
                                        ParkTransactionsState.SelectedTab
                                            .Transactions,
                                }),
                            )
                        }
                    />
                    <TabItem
                        active={
                            layout.selectedTab ===
                            ParkTransactionsState.SelectedTab
                                .TransactionStatistics
                        }
                        description={texts.tabtitleTransactionStatistics()}
                        onClick={() =>
                            this.update(store =>
                                ParkTransactionsState.Layout.stateWrite(store, {
                                    selectedTab:
                                        ParkTransactionsState.SelectedTab
                                            .TransactionStatistics,
                                }),
                            )
                        }
                    />
                    {ParkTransactionsState.Filter.filterActive(
                        this.state.filter,
                    ) && (
                        <ModalSpecialTabResultRefresher
                            onRefreshClick={() =>
                                this.update(
                                    ParkTransactionsState.List.Completed
                                        .forceRefetch,
                                    true,
                                )
                            }
                            successRequest={
                                this.state.completedParkTransactions.statusCode
                                    .statusCode === 200
                            }
                            pendingRequest={
                                this.state.completedParkTransactions.pending
                            }
                        />
                    )}
                </Tabs>
                <Body
                    disabled={this.isBodyDisabled()}
                    slideIns={this.renderSlideIns()}
                    dependingStateSlices={this.getDependentStateSlices()}
                >
                    {this.renderTabBody()}
                </Body>
            </Container>
        );
    }
}
