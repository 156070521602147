import { Localized } from '../common/components/Localized.tsx';
import { RecodeErrorType } from './CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ConfirmationDialog,
    ConfirmationDialogVersions,
} from '../operator-logins/components/ConfirmationDialog.tsx';

export function CloudConnectorRecodeSuccessResponse() {
    const navigate = useNavigate();
    return (
        <ConfirmationDialog
            open
            title={
                <Localized
                    de="Rabatt erfolgreich"
                    fr="Rabais réussi"
                    it="Ribasso riuscito"
                    en="Discount successful"
                />
            }
            onConfirm={() => navigate('..', { replace: true })}
            confirmLabel={<Localized de="OK" fr="OK" it="OK" en="OK" />}
            dialogVersion={ConfirmationDialogVersions.SUCCESS}
        >
            <p>
                <Localized
                    de="Das Ticket wurde erfolgreich rabattiert."
                    fr="Le ticket a été rabattu avec succès."
                    it="Il ticket è stato ribassato con successo."
                    en="The ticket was successfully discounted."
                />
            </p>
        </ConfirmationDialog>
    );
}

function isRecodeErrorType(value?: string): value is RecodeErrorType {
    return Object.values(RecodeErrorType).includes(value as RecodeErrorType);
}

export function getRecodeErrorType(value?: string): RecodeErrorType | null {
    if (isRecodeErrorType(value)) {
        return value;
    }
    return null;
}

export function CloudConnectorRecodeErrorResponse(): JSX.Element {
    const urlParams = useParams<{ type: string }>();
    const type = getRecodeErrorType(urlParams.type);
    const navigate = useNavigate();
    return (
        <ConfirmationDialog
            open
            title={<RecodeResponseErrorTitle />}
            onConfirm={() => navigate('..', { replace: true })}
            confirmLabel={<Localized de="OK" fr="OK" it="OK" en="OK" />}
            dialogVersion={ConfirmationDialogVersions.ERROR}
        >
            <p>
                <CloudConnectorRecodeErrorText type={type} />
            </p>
        </ConfirmationDialog>
    );
}

export function CloudConnectorRecodeErrorText({
    type,
}: {
    type: RecodeErrorType | null;
}): JSX.Element {
    switch (type) {
        case RecodeErrorType.ALREADY_PAID:
            return (
                <Localized
                    de="Das Ticket wurde bereits bezahlt."
                    fr="Le ticket a déjà été payé."
                    it="Il ticket è già stato pagato."
                    en="The ticket has already been paid."
                />
            );
        case RecodeErrorType.IDENTIFICATION_UNKNOWN:
            return (
                <Localized
                    de="Das Ticket ist unbekannt."
                    fr="Le ticket est inconnue."
                    it="Il ticket è sconosciuto."
                    en="The ticket is unknown."
                />
            );
        case RecodeErrorType.PARSING_FAILED:
            return (
                <Localized
                    de="Der Inhalt des QR-Codes ist nicht der eines Tickets."
                    fr="Le contenu du code QR n'est pas celui d'un ticket."
                    it="Il contenuto del QR code non è quello di un ticket."
                    en="The content of the QR code is not that of a ticket."
                />
            );
        case RecodeErrorType.ONE_TIME_EXIT_SCANNED:
            return (
                <Localized
                    de="Einmalausfahrten können nicht rabbattiert werden."
                    fr="Les sorties uniques ne peuvent pas être rabattu."
                    it="Le uscite singole non possono essere ribassate."
                    en="One-time exits cannot be discounted."
                />
            );
        case RecodeErrorType.ZONE_NOT_AVAILABLE:
            return (
                <Localized
                    de="Keine Rabattbedingung verfügbar."
                    fr="Aucune condition de rabais disponible."
                    it="Nessuna condizione di ribasso disponibile."
                    en="No discount condition available."
                />
            );
        case null:
            return (
                <Localized
                    de="Ein unerwarteter Fehler ist aufgetreten."
                    fr="Une erreur inattendue s'est produite."
                    it="Si è verificato un errore imprevisto."
                    en="An unexpected error has occurred."
                />
            );
    }
}

function RecodeResponseErrorTitle() {
    return (
        <Localized
            de="Rabatt fehlgeschlagen"
            fr="Rabais échoué"
            it="Ribasso non riuscito"
            en="Discount failed"
        />
    );
}
