import {
    Box,
    Button,
    Card,
    CardActionArea,
    Link,
    PaletteColor,
    Stack,
    Typography,
} from '@mui/material';
import { Dns, Savings, Settings, Tag } from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { RestrictedComponent } from '../app/components/RestrictedComponent.tsx';
import { OperatorAppRoutes } from '../app/config/OperatorRoutingConfig.tsx';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon.tsx';
import { SxProps } from '@mui/system';
import { Theme, useTheme } from '@mui/material/styles';
import { CountersOverviewTitle } from '../cloud-connector/CloudConnectorCounterOverview.tsx';
import { useEffect, useState } from 'react';
import {
    useServerFetch,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';

interface PeripheryEventsCounter {
    alertsCount: number;
    warningsCount: number;
}

export function MobileHomePage() {
    const { operatorData, currentLogin } = useOperatorContext();
    const theme = useTheme();
    const iconStyle: SxProps<Theme> = {
        fontSize: '32px',
        color: theme => theme.palette.primary.main,
    };

    const [refetchCounter, setRefetchCounter] = useState(0);
    const [eventsCounters, setEventCounters] = useState<PeripheryEventsCounter>(
        { alertsCount: 0, warningsCount: 0 },
    );
    const [peripheryEventsCounter] = useServerFetch<
        PeripheryEventsCounter,
        { operatorId: number; refetchCounter: number }
    >(
        ({ operatorId }) => ({
            url: `/ui-api/operator-account/${operatorId}/periphery-events-counter`,
        }),
        { operatorId: currentLogin.mandantId, refetchCounter: refetchCounter },
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setRefetchCounter(prevCount => prevCount + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useServerSuccessEffect(peripheryEventsCounter, data => {
        setEventCounters(data);
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <Header />
            <Box
                sx={{
                    padding: theme => theme.spacing(4),
                    backgroundColor: theme => theme.palette.blue.light,
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="h2"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                        textAlign: 'center',
                        marginBottom: theme.spacing(1),
                        color: theme => theme.palette.primary.main,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontSize: '34px',
                        fontWeight: 700,
                    })}
                >
                    {operatorData.operatorName}
                </Typography>
                <Stack spacing={2}>
                    <RestrictedComponent
                        route={
                            OperatorAppRoutes.MobileCloudConnectorPeripheries
                        }
                    >
                        <MenuItem
                            to="offstreet-devices"
                            label={
                                <Localized
                                    de="Geräte"
                                    fr="Appareils"
                                    it="Apparecchi"
                                    en="Devices"
                                />
                            }
                            customElementRight={
                                <Box sx={{ display: 'flex' }}>
                                    {eventsCounters.warningsCount > 0 && (
                                        <CounterDisplay
                                            counter={
                                                eventsCounters.warningsCount
                                            }
                                            color={theme.palette.warning}
                                        />
                                    )}
                                    {eventsCounters.alertsCount > 0 && (
                                        <CounterDisplay
                                            counter={eventsCounters.alertsCount}
                                            color={theme.palette.error}
                                        />
                                    )}
                                </Box>
                            }
                            icon={<Dns sx={iconStyle} />}
                        />
                    </RestrictedComponent>
                    <RestrictedComponent
                        route={OperatorAppRoutes.MobileCloudCounterOverview}
                    >
                        <MenuItem
                            to="counter"
                            label={<CountersOverviewTitle />}
                            icon={<Tag sx={iconStyle} />}
                        />
                    </RestrictedComponent>
                    <RestrictedComponent
                        route={OperatorAppRoutes.CloudConnectorRecode}
                    >
                        <MenuItem
                            to="recode"
                            label={
                                <Localized
                                    de="Rabatt erfassen"
                                    fr="Saisir rabais"
                                    it="Registra ribasso"
                                    en="Record discount"
                                />
                            }
                            icon={<Savings sx={iconStyle} />}
                        />
                    </RestrictedComponent>
                </Stack>
            </Box>
        </Box>
    );
}

function CounterDisplay({
    counter,
    color,
}: {
    counter: number;
    color: PaletteColor;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                minWidth: 24,
                height: 24,
                alignContent: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                color: color.contrastText,
                borderRadius: '4px',
                marginRight: '5px',
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: color.main,
                '.menuItemActionArea:hover &': {
                    backgroundColor: color.contrastText,
                    color: color.main,
                },
            }}
        >
            {counter}
        </Box>
    );
}

function MenuItem({
    to,
    label,
    icon,
    customElementRight,
}: {
    to: string;
    label: JSX.Element;
    icon: JSX.Element;
    customElementRight?: JSX.Element;
}) {
    return (
        <Link component={RouterLink} to={to} underline="none">
            <Card elevation={0} sx={{ border: 0 }}>
                <CardActionArea
                    className={'menuItemActionArea'}
                    sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingX: theme.spacing(2),
                        paddingY: theme.spacing(3),
                        backgroundColor: theme =>
                            theme.palette.primary.contrastText,
                        ['&:hover']: {
                            backgroundColor: theme =>
                                theme.palette.primary.light,
                        },
                    })}
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        {icon}
                        <Typography
                            sx={{
                                marginLeft: '24px',
                                fontSize: '20px',
                                fontWeight: 700,
                                color: theme => theme.palette.primary.main,
                            }}
                        >
                            {label}
                        </Typography>
                        <Box sx={{ marginLeft: 'auto' }}>
                            {customElementRight}
                        </Box>
                    </Box>
                </CardActionArea>
            </Card>
        </Link>
    );
}

export function Header() {
    const navigate = useNavigate();
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                padding: theme => theme.spacing(3),
            })}
        >
            <Box
                sx={{ paddingRight: theme => theme.spacing(2) }}
                onClick={() => navigate('/mobile')}
                width={246}
                height={50}
            >
                <ParkingportalLogo />
            </Box>

            <Link component={RouterLink} to={'settings'}>
                <Button
                    sx={{
                        width: 40,
                        height: 40,
                        minWidth: 40,
                        backgroundColor: theme =>
                            theme.palette.primary.contrastText,
                        ['&:hover']: {
                            backgroundColor: theme =>
                                theme.palette.primary.light,
                        },
                    }}
                >
                    <Settings />
                </Button>
            </Link>
        </Box>
    );
}

function ParkingportalLogo() {
    return <SvgIcon icon={'parkingportal'} width={507} height={103} />;
}
