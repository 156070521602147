export enum OperatorAppRoutes {
    Home = 'Home',
    Zones = 'Zones',
    Parkings = 'Parkings',
    ClearancesPermitsList = 'ClearancesPermitsList',
    ClearanceRequests = 'ClearanceRequests',
    Logins = 'Logins',
    ActivityStatement = 'ActivityStatement',
    OnstreetTransactions = 'OnstreetTransactions',
    CustomerAccounts = 'CustomerAccounts',
    SpecialReports = 'SpecialReports',
    PermitTypes = 'PermitTypes',
    TomTransactions = 'TomTransactions',
    ParkingMeters = 'ParkingMeters',
    Tariffs = 'Tariffs',
    UsbSticks = 'UsbSticks',
    PermissionTags = 'PermissionTags',
    ControlHistory = 'ControlHistory',
    Parkmonitor = 'Parkmonitor',
    MiOffice = 'MiOffice',
    ParkingaboUsers = 'ParkingaboUsers',
    ParkingaboUsersAdd = 'ParkingaboUsersAdd',
    ParkingaboUsersInvite = 'ParkingaboUsersInvite',
    ParkingaboUsersDetail = 'ParkingaboUsersDetail',
    ParkingaboUsersProducts = 'ParkingaboUsersProducts',
    ParkingaboUsersProductsAdd = 'ParkingaboUsersProductsAdd',
    ParkingaboUsersProductsDetail = 'ParkingaboUsersProductsDetail',
    ParkingaboUsersVehicles = 'ParkingaboUsersVehicles',
    ParkingaboUsersVehiclesAdd = 'ParkingaboUsersVehiclesAdd',
    ParkingaboUsersVehiclesDetail = 'ParkingaboUsersVehiclesDetail',
    ParkingaboUsersAccountStatement = 'ParkingaboUsersAccountStatement',
    OffstreetTransactions = 'OffstreetTransactions',
    OffstreetTransactionsTicketDetail = 'OffstreetTransactionsTicketDetail',
    OffstreetTransactionsEntryWithoutExitDetail = 'OffstreetTransactionsEntryWithoutExitDetail',
    OffstreetTransactionsContractDetail = 'OffstreetTransactionsContractDetail',
    ParkingaboProducts = 'ParkingaboProducts',
    ParkingaboProductsDetail = 'ParkingaboProductsDetail',
    ParkingaboProductTypes = 'ParkingaboProductTypes',
    ParkingaboProductTypesDetail = 'ParkingaboProductTypesDetail',
    ParkingaboUserCategories = 'ParkingaboUserCategories',
    ParkingaboQuotasDetail = 'ParkingaboQuotasDetail',
    Statistics = 'Stats',
    CloudConnectorPeripheries = 'CloudConnectorPeripheries',
    CloudConnectorPeripheriesDetail = 'CloudConnectorPeripheriesDetail',
    CloudConnectorPayStationManualCorrection = 'CloudConnectorPayStationManualCorrection',
    CloudConnectorRecode = 'CloudConnectorRecode',
    CloudConnectorRecodeSuccessResponse = 'CloudConnectorRecodeSuccessResponse',
    CloudConnectorRecodeErrorResponse = 'CloudConnectorRecodeErrorResponse',
    CloudConnectorRecodeTicket = 'CloudConnectorRecodeTicket',
    CloudConnectorRecodeTicketCondition = 'CloudConnectorRecodeTicketCondition',
    CloudConnectorCounterOverview = 'CloudConnectorCounterOverview',
    CloudConnectorCounterDetail = 'CloudConnectorCounterDetail',
    CloudConnectorGenerateGuestPermit = 'CloudConnectorGenerateGuestPermit',
    CloudConnectorGenerateGuestPermitSuccess = 'CloudConnectorGenerateGuestPermitSuccess',
    CloudConnectorGenerateGuestPermitError = 'CloudConnectorGenerateGuestPermitError',
    PayStationStatement = 'PayStationStatement',
    PayStationCollections = 'PayStationCollections',
    PeripheryHistory = 'PeripheryHistory',

    MobileHomePage = 'MobileHomePage',
    MobileCloudConnectorPeripheries = 'MobileCloudConnectorPeripheries',
    MobileCloudConnectorSettings = 'MobileCloudConnectorSettings',
    MobileCloudCounterOverview = 'MobileCloudCounterOverview',
    MobileCloudCounterDetail = 'MobileCloudCounterDetail',

    Privacy = 'Privacy',
}
