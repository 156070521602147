import { BarrierGateIdentification } from '../common/components/IdentificationItems.tsx';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { Message } from 'dg-web-shared/lib/Localized.ts';

export type BarrierGateIdentificationEntity =
    | BarrierGateIdentificationBadge
    | BarrierGateIdentificationLicensePlate
    | BarrierGateIdentificationQrCode
    | BarrierGateIdentificationTicket;

export type BarrierGateIdentificationBadge = {
    identificationMedia: BarrierGateIdentification.BADGE;
    labelNr: string;
    rfidDecimal: string;
};

export type BarrierGateIdentificationLicensePlate = {
    identificationMedia: BarrierGateIdentification.LICENSE_PLATE;
    licenseNumber: string;
    countryCode: string | null;
    licenseGroup: LicensePlateType;
};

export type BarrierGateIdentificationQrCode = {
    identificationMedia: BarrierGateIdentification.QR_CODE;
    qrCodeId: string;
};

export type BarrierGateIdentificationTicket = {
    identificationMedia: BarrierGateIdentification.TICKET;
    ticketApiString: string;
};

export enum OffstreetTransactionRefundBlockedExplanation {
    MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
    ALREADY_REFUNDED = 'ALREADY_REFUNDED',
    NO_DIGITAL_PURCHASE = 'NO_DIGITAL_PURCHASE',
    NO_ACTIVE_ACCOUNT_TO_REFUND = 'NO_ACTIVE_ACCOUNT_TO_REFUND',
    PAST_REFUND_TIME = 'PAST_REFUND_TIME',
    NON_POSITIVE_PRICE = 'NON_POSITIVE_PRICE',
    PARKING_NOT_COMPLETE = 'PARKING_NOT_COMPLETE',
    CONTRACT_TYPE_NOT_SUPPORTED = 'CONTRACT_TYPE_NOT_SUPPORTED',
    NO_SUBSCRIPTION_CHARGE = 'NO_SUBSCRIPTION_CHARGE',
}

export interface OperatorRefund {
    refundedAt: string;
    refundedBy: string;
    refundAmountRappen: number;
    remark: string;
}

export interface OffstreetTransactionRefundInputValues {
    amount: string;
    remark: string;
}

export interface OffstreetTransactionRefundPayload {
    contractId: number;
    amountRappen: number;
    remark: string;
}

export const enum OffstreetTransactionActions {
    RECODE = 'RECODE',
    REIMBURSE_CUSTOMER = 'REIMBURSE_CUSTOMER',
    DOWNLOAD_RECEIPT = 'DOWNLOAD_RECEIPT',
    CANCEL_ENTRY = 'CANCEL_ENTRY',
    GENERATE_GUEST_PERMIT = 'GENERATE_GUEST_PERMIT',
}

export enum ApprovingContractType {
    PERMIT = 'PERMIT',
    PRODUCT = 'PRODUCT',
}

export interface ApprovingContractInfo {
    approvingContractType: ApprovingContractType;
    infoText: Message;
}
