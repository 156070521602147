import moment from 'moment';
import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import {
    getIdentificationTypesGivenSelection,
    hasBadgeIdentification,
    hasLicensePlateIdentification,
} from '../../common/state/PermitTypeState.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import { Badge } from '../components/AddBadgeSlideIn.tsx';
import { LicensePlate } from '../components/AddLicensePlateSlideIn.tsx';
import { getDurationFromId } from '../components/PermitTimeUnitHelpers.ts';
import { Address } from './AddressState.ts';
import {
    Overlap,
    OverlapUnparsed,
    parseBadgesThatNeedWhitelist,
    parseLpsThatNeedWhitelist,
    parseOverlaps,
} from './SharedParseLogic.ts';
import { ContractQuotaInfos } from 'dg-web-shared/common/components/quota-info/ContractQuotaInfos.ts';

export type { LicensePlate } from '../components/AddLicensePlateSlideIn.tsx';

export namespace Config {
    export interface State {
        permitTypeId: number | null;
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
        durationId: string | null;
        remark: string | null;
        additionalInfo: string | null;
        vigetteAmount: number | null;
        licensePlates: LicensePlate[];
        badges: Badge[];
        onstreetZones: number[] | null;
        address: Address | null;
        email: string | null;
        counterPaymentChannel: Http.OperatorAccount.Permits.CounterPaymentChannel | null;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'permit-detail-PermitCreateState.Config',
        {
            permitTypeId: null,
            startDate: null,
            endDate: null,
            durationId: null,
            remark: null,
            additionalInfo: null,
            vigetteAmount: null,
            licensePlates: [],
            badges: [],
            onstreetZones: null,
            address: null,
            email: null,
            counterPaymentChannel: null,
        },
    );
}

export namespace Layout {
    export interface State {
        createEnabled: boolean;
        validityFromDatePickerOpen: boolean;
        validityDurationPickerOpen: boolean;
        validityToDatePickerOpen: boolean;
        permitTypeSelectionOpen: boolean;
        confirmPressedOnError: boolean;
        vignetteCountSelectionOpen: boolean;
        zonePickerOpen: boolean;
        counterPaymentChannelSelectionOpen: boolean;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'permit-detail-PermitCreateState.Layout',
        {
            createEnabled: false,
            validityFromDatePickerOpen: false,
            validityDurationPickerOpen: false,
            validityToDatePickerOpen: false,
            permitTypeSelectionOpen: false,
            confirmPressedOnError: false,
            vignetteCountSelectionOpen: false,
            zonePickerOpen: false,
            counterPaymentChannelSelectionOpen: false,
        },
    );
}

const getZoneIds = (
    permitType: PermitTypeState.PermitType,
    config: Config.State,
): number[] => {
    if (permitType.selectZones === 'NO_CHOICE' || !permitType.selectZones) {
        return permitType.zones.map(z => z.id);
    } else {
        return config.onstreetZones || [];
    }
};

export const makePermitPayload = (
    store: Flux.Store,
): Http.OperatorAccount.Permits.CalcPayload | null => {
    const config = Config.get(store);
    const permitTypes = PermitTypeState.get(store);
    const permitTypeId = config.permitTypeId;
    const lps = config.licensePlates;
    const badges = config.badges;
    const identificationTypes = getIdentificationTypesGivenSelection(
        permitTypes.data,
        permitTypeId ? [permitTypeId] : [],
    );

    if (!permitTypeId) {
        return null;
    } else if (
        hasLicensePlateIdentification(identificationTypes) &&
        (!lps || lps.length === 0)
    ) {
        return null;
    } else if (
        hasBadgeIdentification(identificationTypes) &&
        (!badges || badges.length === 0)
    ) {
        return null;
    } else {
        const permitType = PermitTypeState.getById(store, permitTypeId);
        if (!permitType) {
            return null;
        } else {
            const from: Maybe<string> = thenElse(
                config.startDate,
                d => Formatter.isoYearMonthDay(d),
                null,
            );
            const to: Maybe<string> = thenElse(
                config.endDate,
                d => Formatter.isoYearMonthDay(d),
                null,
            );
            const duration =
                permitType.timeSelectionType === 'from_to'
                    ? null
                    : getDurationFromId(
                          config.durationId,
                          permitType.validDurationsV2 || [],
                      );
            let durationType: DurationType | null = null;
            let time: Maybe<number> = null;
            if (duration) {
                durationType = duration.type;
                time = duration.quantity;
            }
            const licensePlateIds = lps.map(lp => lp.id);
            const badgeIds = badges.map(badge => badge.id);
            const additionalInfo = config.additionalInfo;
            const remark = config.remark;
            const vignetteAmount = config.vigetteAmount || 0;
            const onstreetZoneIds = hasLicensePlateIdentification(
                identificationTypes,
            )
                ? getZoneIds(permitType, config)
                : [];
            const offstreetZoneIds = hasBadgeIdentification(identificationTypes)
                ? getZoneIds(permitType, config)
                : [];
            const address = config.address;

            if (
                !from ||
                (hasLicensePlateIdentification(identificationTypes) &&
                    !onstreetZoneIds) ||
                (hasBadgeIdentification(identificationTypes) &&
                    !offstreetZoneIds) ||
                (!duration && !to) ||
                (onstreetZoneIds.length === 0 &&
                    offstreetZoneIds.length === 0) ||
                (permitType.mubstBeLinkedToCustomerAccount && !config.email)
            ) {
                return null;
            } else {
                return {
                    permitTypeId,
                    from,
                    to,
                    time,
                    durationType,
                    additionalInfo,
                    remark,
                    vignetteAmount,
                    licensePlateIds,
                    badgeIds,
                    onstreetZoneIds,
                    offstreetZoneIds,
                    address,
                    email: config.email,
                    counterPaymentChannel: config.counterPaymentChannel,
                };
            }
        }
    }
};

export namespace Calc {
    export interface CalcData {
        price: number;
        priceUnit: DurationType | null;
        validFrom: moment.Moment;
        validTo: moment.Moment;
        emailIsNotALogin: boolean;
        overlappingLpPermits: Overlap[];
        overlappingBadgePermits: Overlap[];
        lpsThatNeedWhitelist: LicensePlate[];
        badgesThatNeedWhitelist: Badge[];
        badgesThatNeedAccount: string[];
        additionalInfosFromWhitelist: string[];
        quotaInfo: ContractQuotaInfos[] | null;
    }

    interface CalcDataUnparsed
        extends Omit<
            CalcData,
            | 'validFrom'
            | 'validTo'
            | 'overlappingLpPermits'
            | 'overlappingBadgePermits'
        > {
        validFrom: string;
        validTo: string;
        overlappingLpPermits: OverlapUnparsed[] | null;
        overlappingBadgePermits: OverlapUnparsed[] | null;
    }

    const sideEffects = (store: Flux.Store, state: State): void => {
        const payload = makePermitPayload(store);
        if (!!payload && state.shouldFetch) {
            store.update(calcPrice, payload);
        }
    };

    const parseBody = (body: CalcDataUnparsed): CalcData => {
        return {
            price: body.price,
            priceUnit: body.priceUnit,
            emailIsNotALogin: body.emailIsNotALogin,
            validFrom: Parser.isoToMoment(body.validFrom),
            validTo: Parser.isoToMoment(body.validTo),
            overlappingLpPermits: thenElse(
                body.overlappingLpPermits,
                ol => parseOverlaps(ol),
                [],
            ),
            overlappingBadgePermits: thenElse(
                body.overlappingBadgePermits,
                ol => parseOverlaps(ol),
                [],
            ),
            lpsThatNeedWhitelist: parseLpsThatNeedWhitelist(
                body.lpsThatNeedWhitelist,
            ),
            badgesThatNeedWhitelist: parseBadgesThatNeedWhitelist(
                body.badgesThatNeedWhitelist,
            ),
            badgesThatNeedAccount: body.badgesThatNeedAccount,
            additionalInfosFromWhitelist:
                body.additionalInfosFromWhitelist || [],
            quotaInfo: body.quotaInfo,
        };
    };

    export type State = ServerStateSlice.ServerState<Maybe<CalcData>>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<Maybe<CalcData>>(
            'permit-detail-CalcData',
            () => null,
            sideEffects,
            parseBody,
        );

    const calcPrice = AsyncRequest.request(
        Http.OperatorAccount.Permits.calc,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            if (res.statusCode.cls.success) {
                // If additional infos is not set and there is a suggestion
                // from whitelist, insert it into config state
                const state = get(store);

                Config.set(store, (s: Config.State): Config.State => {
                    if (
                        !s.additionalInfo &&
                        state.data &&
                        state.data.additionalInfosFromWhitelist.length > 0
                    ) {
                        s.additionalInfo =
                            state.data.additionalInfosFromWhitelist[0];
                    }
                    return s;
                });
            }
            return 'PermitCreateState-calcPrice';
        },
    );
}

export namespace CreateResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'permit-detail-CreateResponse',
        () => null,
    );
}

export const resetAllState = (store: Flux.Store) => {
    Config.reset(store);
    Layout.reset(store);
    Calc.reset(store);
    CreateResponse.reset(store);
};
