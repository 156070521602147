import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { RestrictedComponent } from '../app/components/RestrictedComponent.tsx';
import { OperatorAppRoutes } from '../app/config/OperatorRoutingConfig.tsx';
import { CameraAlt } from '@mui/icons-material';
import { getBridge } from '../app/components/Native.tsx';
import { useState } from 'react';
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import {
    ConfirmationDialog,
    ConfirmationDialogVersions,
} from '../operator-logins/components/ConfirmationDialog.tsx';

export function CloudConnectorRecoder() {
    const [openScanNotAvailableDialog, setOpenScanNotAvaialbleDialog] =
        useState(false);

    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Rabatt erfassen"
                    fr="Saisir rabais"
                    it="Registra ribasso"
                    en="Record discount"
                />
            }
            backTo={'..'}
        >
            <RestrictedComponent route={OperatorAppRoutes.CloudConnectorRecode}>
                <Box sx={{ padding: theme => theme.spacing(3) }}>
                    <BaseCard
                        onClick={() => {
                            const bridge = getBridge();
                            if (bridge && bridge.scanQRCode) {
                                bridge.scanQRCode();
                            } else {
                                setOpenScanNotAvaialbleDialog(true);
                            }
                        }}
                    >
                        <Typography textTransform="uppercase" fontWeight="bold">
                            <Localized
                                de="Ticket"
                                fr="Ticket"
                                it="Ticket"
                                en="Ticket"
                            />
                        </Typography>
                        <CameraAlt sx={{ fontSize: '32px' }} />
                    </BaseCard>
                </Box>
            </RestrictedComponent>

            <ScanNotAvailableDialog
                open={openScanNotAvailableDialog}
                onClose={() => setOpenScanNotAvaialbleDialog(false)}
            />

            <Outlet />
        </ParkingPortalLayoutWithHeader>
    );
}

function ScanNotAvailableDialog({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={<Localized de="OK" fr="OK" it="OK" en="OK" />}
            onConfirm={onClose}
            title={
                <Localized
                    de="Scannen nicht möglich"
                    fr="Numérisation impossible"
                    it="Scansione impossibile"
                    en="Scanning not possible"
                />
            }
            dialogVersion={ConfirmationDialogVersions.ERROR}
        >
            <p>
                <Localized
                    de="Stellen Sie sicher, dass Sie die aktuelle Version der App verwenden."
                    fr="Assurez-vous que vous utilisez la dernière version de l'application."
                    it="Assicurati di utilizzare la versione più recente dell'app."
                    en="Make sure you are using the latest version of the app."
                />
            </p>
        </ConfirmationDialog>
    );
}

function BaseCard({
    onClick,
    children,
}: {
    onClick: () => void;
    children: React.ReactNode;
}) {
    return (
        <Card elevation={0} sx={{ border: 0 }}>
            <CardActionArea
                onClick={onClick}
                sx={theme => ({
                    color: theme.palette.primary.main,
                    padding: theme.spacing(3),
                    backgroundColor: theme.palette.blue.light,
                    ['&:hover']: {
                        backgroundColor: theme => theme.palette.primary.light,
                    },
                })}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {children}
                </Stack>
            </CardActionArea>
        </Card>
    );
}
