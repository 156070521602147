import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import moment from 'moment';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    parseTomTransaction,
    TomTransaction,
} from 'dg-web-shared/model/TomTransaction.ts';

export namespace List {
    export type Data = { data: TomTransaction[]; size: number };
    export type State = ServerStateSlice.ServerState<Data>;

    const generateTransactionState = (
        key: string,
        shouldFetch: (s: Flux.Store) => boolean,
        fetcher: (s: Flux.Store) => Flux.Update<object>,
    ) =>
        ServerStateSlice.generateServerState<Data>(
            key,
            () => ({ data: [], size: 0 }),
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch && shouldFetch(store)) {
                    store.update(fetcher(store));
                }
            },
            b => ({
                size: b.size,
                data: b.data.map(parseTomTransaction),
            }),
        );

    export namespace Completed {
        const fetchParkTransactions = (s: Flux.Store): Flux.Update<object> => {
            return AsyncRequest.request(
                () =>
                    Http.OperatorAccount.TomParkTransactions.get({
                        validFrom: Filter.get(s).validFrom,
                        validTo: Filter.get(s).validTo,
                        toms: Filter.get(s).toms,
                    }),
                setResponse,
            );
        };

        export const { get, reset, setResponse, forceRefetch } =
            generateTransactionState(
                'tom-park-transactions-List-Completed',
                s =>
                    isDefined(Filter.get(s).validFrom) ||
                    isDefined(Filter.get(s).validTo) ||
                    Filter.get(s).toms.length > 0,
                fetchParkTransactions,
            );
    }
}

export namespace Filter {
    export interface State {
        filterSlideInVisible: boolean;

        validFrom: Maybe<moment.Moment>;
        validTo: Maybe<moment.Moment>;
        validDateSelectionVisible: boolean;

        toms: number[];
        tomSelectionVisible: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'tom-park-transactions-Filter',
        {
            filterSlideInVisible: false,
            validFrom: null,
            validTo: null,
            validDateSelectionVisible: false,

            toms: [],
            tomSelectionVisible: false,
        },
    );

    export const dateFilterActive = (s: State): boolean =>
        isDefined(s.validFrom) || isDefined(s.validTo);

    export const tomsFilterActive = (s: State): boolean => s.toms.length > 0;

    export const filterActive = (s: State): boolean =>
        dateFilterActive(s) || tomsFilterActive(s);
}

export enum SelectedTab {
    Transactions,
    TransactionStatistics,
}

export namespace Layout {
    export interface State {
        selectedTab?: SelectedTab;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'tom-park-transactions-Layout',
        {
            selectedTab: SelectedTab.Transactions,
        },
    );
}

export namespace Detail {
    export interface State {
        selectedTransaction: Maybe<TomTransaction>;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'tom-park-transactions-Detail',
        { selectedTransaction: undefined },
    );
}
