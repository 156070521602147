import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import { request } from '../../AsyncRequest.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { PermitStatus } from './PermitsState.ts';

type SearchEntity = Http.OperatorAccount.Permits.SearchEntity;

interface Context {
    search: string;
    entity: SearchEntity;
}

interface Data {
    clearances: Clearance[];
    permitsWithoutClearances: Permit[];
}

export interface LicensePlate {
    id: number;
    licensePlateNr: string;
    type: LicensePlateType;
    country: string;
}

export interface Clearance {
    id: number;
    validFrom: string;
    validTo: string;
    permitTypeId: number;
    permitTypeName: string;
    licensePlateNr: string;
    licensePlateCountry: Maybe<string>;
    licensePlateType: Maybe<LicensePlateType>;
    licensePlateId: Maybe<number>;
    badgeLabelNr: string;
    personalNumber: string;
    contractNumber: string;
    infos: string;
    permits: Permit[];
    isActive: boolean;
}

export interface Permit {
    id: number;
    operatorId: number;
    permitTypeId: number;
    permitTypeName: string;
    operatorLoginId: Maybe<number>;
    validFrom: string;
    validTo: string;
    paydate: Maybe<string>;
    generated: Maybe<string>;
    additionalInfos: Maybe<string>;
    remarks: Maybe<string>;
    licensePlates: LicensePlate[];
    badges: Badge[];
    status: PermitStatus;
}

export interface Badge {
    id: number;
    labelNr: string;
}

export type State = ContextualServerStateSlice.ContextualState<Data>;
export const { get, refetchSameContext } =
    ContextualServerStateSlice.generateContextualState<Context, Data>({
        key: 'ClearancePermitSearchResult',
        requestGenerator: Http.OperatorAccount.Permits.search,
        request,
        parseBody: (b: Data): Data => {
            return b;
        },
        debounceTime: 300,
    });
