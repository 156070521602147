import * as Sentry from '@sentry/browser';
import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';

export interface Action {
    name: string;
    payload: unknown;
}

interface ActionHistoryState {
    actions: Action[];
}

export const { get, set } = Flux.generateState<ActionHistoryState>(
    'app-ActionHistoryState',
    {
        actions: [],
    },
);

export const setAction = (store: Flux.Store, action: Action) => {
    set(store, (s: ActionHistoryState): ActionHistoryState => {
        const previousAction = s.actions[s.actions.length - 1];

        const shouldLog =
            action.name.indexOf('fetch') === -1 &&
            action.name.indexOf('log-ignore') === -1;

        if (!shouldLog) {
            return s;
        }

        const shouldSendToServer =
            !!previousAction && previousAction.name !== action.name;

        if (shouldSendToServer) {
            superagent
                .post('/ui-api/operator-account/flux-action')
                .send({
                    action: previousAction.name,
                    payload: previousAction.payload,
                    appVersion: SENTRY_RELEASE.id,
                })
                .end();
        }

        s.actions.push(action);
        if (s.actions.length > 50) {
            s.actions.shift();
        }

        Sentry.getCurrentScope().setContext('flux actions', {
            value: s.actions,
        });
        return s;
    });
};
