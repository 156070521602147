import {
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import {
    IconButton16px,
    IconButton16pxType,
    IconButton24px,
    IconButton24pxType,
} from '../buttons/IconButton.tsx';
import { UiContext } from '../Context.ts';
import { Icon16 } from '../icons/Icon.tsx';
import { css } from '@emotion/css';
import { clearfix } from 'dg-web-shared/ui/clearfix.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import { ColorHex } from '../Colors.ts';
import React from 'react';

interface LabelProps {
    label: React.ReactNode;
    rightText?: Maybe<string>;
    focused: boolean;
    hovered: boolean;
    hidden?: boolean;
    disabled?: boolean;
    context?: UiContext;
    toolTip?: React.ReactNode;
    toolTipRight?: boolean;
}

export class Label extends React.Component<LabelProps> {
    renderRightText(): JSX.Element | null {
        return thenElse(
            this.props.rightText,
            t => {
                return <div className={css({ float: 'right' })}>{t}</div>;
            },
            null,
        );
    }

    render() {
        function getLabeledElementColor(
            contextDarkblue: boolean,
            dataHovered: boolean,
            dataFocused: boolean,
            dataDisabled: boolean,
        ) {
            if (dataDisabled) {
                return ColorHex.rgba(ColorHex.darkblue, 0.6);
            }
            if (contextDarkblue) {
                return ColorHex.white;
            }
            if (dataHovered || dataFocused) {
                return ColorHex.lightblue;
            }
            return ColorHex.rgba(ColorHex.darkblue, 1);
        }

        return (
            <>
                <label
                    className={css({
                        display: 'block',
                        ...OperatorTypo.label,
                        'text-transform': 'uppercase',
                        color: getLabeledElementColor(
                            this.props.context === 'darkblue',
                            this.props.hovered,
                            this.props.focused,
                            this.props.disabled || false,
                        ),
                        visibility: this.props.hidden ? 'hidden' : undefined,
                    })}
                >
                    <div
                        className={css({
                            userSelect: 'none',
                        })}
                    >
                        {this.props.label}
                        {this.renderRightText()}
                        {this.props.toolTip && (
                            <div
                                className={css({
                                    display: 'inline-block',
                                    verticalAlign: 'bottom',
                                    marginLeft: '10px',
                                })}
                            >
                                <InfoButton
                                    type={IconButton16pxType.INFO}
                                    tooltip={this.props.toolTip}
                                    tooltipRight={this.props.toolTipRight}
                                />
                            </div>
                        )}
                    </div>
                </label>
            </>
        );
    }
}

interface BelowContentProps {
    underline: boolean;
    focused: boolean;
    hovered: boolean;
    errorText: React.ReactNode;
    context?: UiContext;
}

export const BelowContent = (p: BelowContentProps): JSX.Element => {
    return (
        <div
            className={css([
                {
                    minHeight: '16px',
                    ...OperatorTypo.caption,
                },
                !!p.errorText && [
                    {
                        color: ColorHex.fAbgelehnt,
                    },
                    p.context === 'darkblue' && { background: ColorHex.white },
                ],
                p.underline && [
                    {
                        borderTop: `1px solid ${ColorHex.rgba(
                            ColorHex.darkblue,
                            0.25,
                        )}`,
                    },
                    p.hovered && {
                        borderTop: `1px solid ${ColorHex.rgba(
                            ColorHex.lightblue,
                            0.25,
                        )}`,
                    },
                    p.focused && {
                        borderTop: `2px solid ${ColorHex.lightblue}`,
                    },
                    !!p.errorText && {
                        borderTop: `2px solid ${ColorHex.fAbgelehnt}`,
                    },
                    p.context === 'darkblue' && [
                        {
                            borderTop: `1px solid ${ColorHex.rgba(
                                ColorHex.white,
                                0.25,
                            )}`,
                        },
                        p.focused && {
                            borderTop: `2px solid ${ColorHex.rgba(
                                ColorHex.white,
                                0.5,
                            )}`,
                        },
                        !!p.errorText && {
                            borderTop: `2px solid ${ColorHex.white}`,
                        },
                    ],
                ],
            ])}
        >
            {p.errorText}
        </div>
    );
};

const ContentIcon = (p: {
    focused: boolean;
    hovered: boolean;
    icon: JSX.Element;
}): JSX.Element => {
    return (
        <div
            className={css({
                position: 'absolute',
                right: 0,
                bottom: '-2px',
                color: p.focused
                    ? ColorHex.lightblue
                    : p.hovered
                      ? ColorHex.lightblue
                      : ColorHex.rgba(ColorHex.darkblue, 0.6),
            })}
            data-focused={p.focused}
            data-hovered={p.hovered}
        >
            <Icon16 icon={p.icon} />
        </div>
    );
};

interface ContentProps {
    focused: boolean;
    hovered: boolean;
    icon?: Maybe<JSX.Element>;
    empty: boolean;
    userSelectable: boolean;
    children?: React.ReactNode;
    disabled?: Maybe<boolean>;
    context?: UiContext;
}

export const Content = (p: ContentProps): JSX.Element => {
    const icon = p.icon;
    return (
        <div
            className={css([
                {
                    ...OperatorTypo.headingOne,
                    fontWeight: 500,
                    paddingTop: '2px',
                    color: ColorHex.darkblue,
                    position: 'relative',
                },
                p.context === 'darkblue' && {
                    color: ColorHex.white,
                },
                p.hovered && {
                    color: ColorHex.lightblue,
                },
                p.focused && [
                    {
                        color: ColorHex.lightblue,
                    },
                    p.context === 'darkblue' && {
                        color: ColorHex.white,
                    },
                ],
                !p.userSelectable && {
                    userSelects: 'none',
                },
                p.disabled && {
                    color: ColorHex.rgba(ColorHex.darkblue, 0.6),
                },
            ])}
        >
            {p.children}
            {isDefined(icon) ? (
                <ContentIcon
                    focused={p.focused}
                    hovered={p.hovered}
                    icon={icon}
                />
            ) : null}
        </div>
    );
};

interface InfoButtonProps {
    type: IconButton16pxType;
    tooltip?: React.ReactNode;
    tooltipRight?: boolean;
}

export function InfoButton(p: InfoButtonProps) {
    return (
        <IconButton16px
            type={p.type}
            tooltip={p.tooltip}
            onClick={() => {}}
            tooltipRight={p.tooltipRight}
            withoutCircle={true}
        />
    );
}

interface ActionButtonProps {
    type: IconButton24pxType;
    onMouseOut?: () => void;
    onMouseOver?: () => void;
    onClick: (() => void) | null | undefined;
    tooltip?: Maybe<string>;
    verticalAlignment?: Maybe<'69' | 'bottom'>;
}

export const ActionButtonsContainer = (p: {
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <div
            className={css([
                {
                    position: 'absolute',
                    left: '100%',
                    top: 0,
                    bottom: 0,
                    paddingRight: '2px',
                    width:
                        React.Children.count(p.children) === 1
                            ? '40px'
                            : '72px',
                },
                clearfix,
            ])}
        >
            {p.children}
        </div>
    );
};

export const ActionButton = (p: ActionButtonProps): JSX.Element => (
    <Clickable
        className={css({
            position: 'absolute',
            top: 0,
            bottom: 0,
            marginLeft: '8px',
        })}
        element="div"
        onMouseOver={p.onMouseOver}
        onMouseOut={p.onMouseOut}
    >
        <IconButton24px
            type={p.type}
            tooltip={p.tooltip}
            onClick={e => {
                e?.stopPropagation();
                if (p.onClick) {
                    p.onClick();
                }
            }}
        />
    </Clickable>
);

export enum ActionButtons {
    none,
    one,
    two,
}

interface LabeledElementProps {
    children?: React.ReactNode;
    actionButtons?: ActionButtons;
    onClick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
}

export const LabeledElement = (p: LabeledElementProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                position: 'relative',
                paddingTop: '16px',
                paddingRight:
                    p.actionButtons === ActionButtons.one
                        ? '40px'
                        : p.actionButtons === ActionButtons.two
                          ? '64px'
                          : undefined,
            })}
            onClick={p.onClick}
            onMouseOver={p.onMouseOver}
            onMouseOut={p.onMouseOut}
            disabled={isUndefined(p.onClick)}
        >
            {p.children}
        </Clickable>
    );
};

export abstract class HoverableBlock<P> extends React.Component<
    P,
    { actionHovered?: boolean; itemHovered?: boolean }
> {
    constructor(p: P) {
        super(p);
        this.state = { actionHovered: false, itemHovered: false };
    }
}
