import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
    getCloudConnectorPeripheryType,
    usePeripheryDetailsOutlet,
} from '../CloudConnectoPeripheriesDetail.tsx';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { isMobileRoute } from '../../layout/components/BreadCrumb.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../../ui/modal/OperatorRoutedModal.tsx';
import { ParkingPortalLayoutWithHeader } from '../../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { Box, Button } from '@mui/material';
import {
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    CoinType,
    getCoinTypeByString,
} from '../CloudConnectorPeripheriesOverview.tsx';
import { numberToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import Alert from '@mui/material/Alert';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { MobileSectionTitle } from '../../shared-mui-components/MobileSectionTitle.tsx';
import { UncontrolledMobileNumericInput } from '../../shared-mui-components/UncontrolledMobileNumericInput.tsx';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

enum CoinChangeDirection {
    INSERT = 'INSERT',
    REMOVE = 'REMOVE',
}

export function PayStationManualCorrection() {
    const urlParams = useParams<{
        zoneId: string;
        peripheryType: string;
        onPremiseId: string;
        coinType: string;
    }>();
    const operatorId = useOperatorContext().currentLogin.mandantId;
    const zoneId = urlParams.zoneId ? parseInt(urlParams.zoneId, 10) : null;
    const peripheryType = getCloudConnectorPeripheryType(
        urlParams.peripheryType,
    );
    const onPremiseId = urlParams.onPremiseId
        ? parseInt(urlParams.onPremiseId, 10)
        : null;
    const coinType = getCoinTypeByString(urlParams.coinType);
    const {
        peripheryName,
        isCashlessPaystation,
        refetchCloudConnectorPeriphery,
    } = usePeripheryDetailsOutlet();

    const [manualCorrectionState, manualCorrection] = useServerWrite<
        PayStationManualCorrectionPayload,
        null
    >(context => ({
        url: `/ui-api/operator-account/${context.operatorId}/zone-barrier-gate/${context.zoneId}/periphery/${context.peripheryType}/${context.onPremiseId}/manual-correction`,
    }));

    const [coinChangeValue, setCoinChangeValue] = useState('0');
    const [coinChangeWarning, setCoinChangeWarning] = useState(false);
    const [coinChangeDirection, setCoinChangeDirection] = useState(
        CoinChangeDirection.INSERT,
    );

    const navigate = useNavigate();
    useServerSuccessEffect(manualCorrectionState, () => {
        navigate('..');
        refetchCloudConnectorPeriphery();
    });
    const loading = manualCorrectionState.status === RequestStatus.PENDING;
    const isMobile = isMobileRoute();

    if (
        peripheryType != CloudConnectorPeripheryType.PAY_STATION ||
        isCashlessPaystation ||
        !zoneId ||
        !onPremiseId ||
        !coinType
    ) {
        return <Navigate to={'..'} />;
    }
    return (
        <OperatorRoutedModal
            variant={isMobile ? ModalVariant.MOBILE : ModalVariant.SMALL}
            backUrl={'..'}
            render={() => (
                <ParkingPortalLayoutWithHeader
                    title={
                        <Localized
                            de="Münzstand anpassen"
                            fr="Modifier état monnaie"
                            it="Modica stato moneta"
                            en="Modidy coin state"
                        />
                    }
                    backTo={'..'}
                    scrollable
                    padded
                >
                    <MobileSectionTitle>{peripheryName}</MobileSectionTitle>
                    {manualCorrectionState.status === RequestStatus.ERROR && (
                        <Alert severity="error" sx={{ margin: '0 0 20px 0' }}>
                            <Localized
                                de="Es ist ein Fehler aufgetreten."
                                fr="Une erreur est survenue."
                                it="Si è verificato un errore."
                                en="An error has occurred."
                            />
                        </Alert>
                    )}
                    {coinChangeWarning && (
                        <Alert severity="warning" sx={{ margin: '0 0 20px 0' }}>
                            <Localized
                                de="Münzstand Anpassung darf nicht 0 sein"
                                fr="L'ajustement du niveau des pièces ne doit pas être égal à 0"
                                it="La regolazione del livello della moneta non deve essere 0"
                                en="Coin balance adjustment must not be 0"
                            />
                        </Alert>
                    )}
                    <UncontrolledMobileNumericInput
                        label={numberToLocalPrice(
                            'de',
                            getChfByCoinType(coinType),
                        )}
                        value={coinChangeValue}
                        onChange={e => {
                            const value = Number(e.target.value);
                            if (!isNaN(value)) {
                                setCoinChangeValue(
                                    Math.floor(Math.abs(value)).toString(),
                                );
                                setCoinChangeWarning(false);
                            }
                        }}
                    />
                    <ToggleButtonGroup
                        exclusive={true}
                        value={coinChangeDirection}
                        onChange={(_, value) => setCoinChangeDirection(value)}
                        sx={{
                            width: '100%',
                            diplay: 'flex',
                            flexDirection: 'row',
                            marginTop: '24px',
                            borderRadius: '12px',
                        }}
                    >
                        <ToggleButton
                            value={CoinChangeDirection.INSERT}
                            sx={theme => ({
                                flexGrow: 1,
                                borderRadius: '12px 0 0 12px',
                                backgroundColor: theme.palette.blue.light,
                                color: theme.palette.primary.main,
                                fontSize: '16px',
                                fontWeight: 500,
                                '&.Mui-selected': {
                                    fontWeight: 800,
                                    color: theme.palette.primary.main,
                                    backgroundColor: theme.palette.blue.medium,
                                    '&:hover': {
                                        backgroundColor:
                                            theme.palette.blue.medium,
                                    },
                                },
                            })}
                        >
                            <Localized
                                de="Einfügen"
                                fr="Insérer"
                                it="Aggiungere"
                                en="Add"
                            />
                        </ToggleButton>
                        <ToggleButton
                            value={CoinChangeDirection.REMOVE}
                            sx={theme => ({
                                flexGrow: 1,
                                borderRadius: '0 12px 12px 0',
                                backgroundColor: theme.palette.blue.light,
                                color: theme.palette.primary.main,
                                fontSize: '16px',
                                fontWeight: 500,
                                '&.Mui-selected': {
                                    fontWeight: 800,
                                    color: theme.palette.primary.main,
                                    backgroundColor: theme.palette.blue.medium,
                                    '&:hover': {
                                        backgroundColor:
                                            theme.palette.blue.medium,
                                    },
                                },
                            })}
                        >
                            <Localized
                                de="Entfernen"
                                fr="Enlever"
                                it="Rimuovere"
                                en="Remove"
                            />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            if (coinChangeValue == '0') {
                                setCoinChangeWarning(true);
                            } else {
                                const coinChange =
                                    coinChangeDirection ===
                                    CoinChangeDirection.INSERT
                                        ? Number(coinChangeValue)
                                        : -Number(coinChangeValue);
                                manualCorrection({
                                    operatorId: operatorId,
                                    zoneId: zoneId,
                                    onPremiseId: onPremiseId,
                                    peripheryType: peripheryType,
                                    coinType: coinType,
                                    coinChange: coinChange,
                                });
                            }
                        }}
                        loading={
                            manualCorrectionState.status ===
                            RequestStatus.PENDING
                        }
                        sx={{
                            padding: '24px',
                            fontSize: '16px',
                            marginTop: '24px',
                        }}
                        disabled={loading}
                    >
                        {loading ? (
                            <PresetLoadingSpinner
                                color="inherit"
                                preset={LoadingSpinnerPresets.EmbedInButton}
                            />
                        ) : (
                            <Localized
                                de="Speichern"
                                fr="Sauvegarder"
                                it="Salva"
                                en="Save"
                            />
                        )}
                    </Button>
                </ParkingPortalLayoutWithHeader>
            )}
        />
    );
}

function getChfByCoinType(coinType: CoinType): number {
    switch (coinType) {
        case CoinType.CHF_10:
            return 0.1;
        case CoinType.CHF_20:
            return 0.2;
        case CoinType.CHF_50:
            return 0.5;
        case CoinType.CHF_100:
            return 1;
        case CoinType.CHF_200:
            return 2;
        case CoinType.CHF_500:
            return 5;
    }
}

interface PayStationManualCorrectionPayload {
    operatorId: number;
    zoneId: number;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    coinType: CoinType;
    coinChange: number;
}
