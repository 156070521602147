import { MapContainer, TileLayer, Tooltip } from 'react-leaflet';
import * as L from 'leaflet';
import { GeoJsonObject, Point } from 'geojson';
import { geoJson, WktParseResultTag } from 'dg-web-shared/lib/geo/geoJson.ts';
import { Color, Geo } from 'dg-web-shared/lib/geo/Geo.tsx';
import { css } from '@emotion/css';

import 'leaflet/dist/leaflet.css';

export interface ZoneMapViewProps {
    zonesData: ZoneProps[];
    onZoneClick?: (id: number) => void;
}

export interface ZoneProps {
    id: number;
    externalName: string;
    externalId?: number;
    geodataText: string | null;
}

interface ParsedZoneProps {
    id: number;
    externalName: string;
    externalId?: number;
    geodataText: string;
    parsedGeoJson: GeoJsonObject;
}

export function ZoneMapView({ zonesData, onZoneClick }: ZoneMapViewProps) {
    // if no click callback defined, pass empty function
    const onZoneClickClean = onZoneClick ? onZoneClick : () => {};

    const parsedZonesData = zonesData.map(zone => {
        const parsed = geoJson(zone.geodataText);

        const parsedGeoJson =
            parsed?.type === WktParseResultTag.OK ? parsed.result : null;

        // display warning --> could be removed
        if (parsedGeoJson === null) {
            console.warn(
                `Warning: Could not parse the geodataText "${zone.geodataText}". This location will be omitted in the map render.`,
            );
        }

        return { ...zone, parsedGeoJson: parsedGeoJson };
    });

    const filteredZonesData: ParsedZoneProps[] = parsedZonesData.filter(
        zone => zone.parsedGeoJson,
    ) as ParsedZoneProps[];

    const mapProps = { bounds: calculateZoneBounds(filteredZonesData) };

    return (
        <MapContainer
            {...mapProps}
            style={{
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}
        >
            <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {filteredZonesData.map(zone => {
                return (
                    <Geo
                        geoJson={zone.parsedGeoJson}
                        key={zone.id}
                        color={Color.MARKER}
                        hoverColor={Color.MARKER_HOVER}
                        onClick={() => {
                            onZoneClickClean(zone.id);
                        }}
                    >
                        <CustomTooltip
                            name={zone.externalName}
                            id={zone.externalId}
                        />
                    </Geo>
                );
            })}
        </MapContainer>
    );
}

export function SingleZoneView({ zone }: { zone: ZoneProps }) {
    const parsed = geoJson(zone.geodataText);
    let parsedGeoJson;
    if (parsed?.type === WktParseResultTag.OK) {
        parsedGeoJson = parsed.result;
    } else {
        console.warn(
            `Warning: Could not parse the geodataText "${zone.geodataText}". This location will be omitted in the map render.`,
        );
    }
    const mapProps = { bounds: calculateZoneBounds([zone]) };

    return (
        <MapContainer
            {...mapProps}
            style={{
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}
        >
            <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {parsedGeoJson && (
                <Geo
                    geoJson={parsedGeoJson}
                    key={zone.id}
                    color={Color.MARKER}
                />
            )}
        </MapContainer>
    );
}

function CustomTooltip({
    name,
    id,
}: {
    name: React.ReactNode;
    id?: React.ReactNode;
}) {
    return (
        <Tooltip
            sticky={true}
            className={css({
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '0px!important',
            })}
        >
            <b>
                {id ? (
                    <>
                        {id}
                        {' | '}
                        {name}
                    </>
                ) : (
                    name
                )}
            </b>
        </Tooltip>
    );
}

function calculateZoneBounds(zonesData: ZoneProps[]): L.LatLngBounds {
    const mergedBounds = zonesData.reduce(
        (accumulator: L.LatLngBounds | null, element: ZoneProps) => {
            const parsed = geoJson(element.geodataText);
            let parsedGeoJson =
                parsed?.type === WktParseResultTag.OK ? parsed.result : null;
            if (parsedGeoJson !== null) {
                // reverse coordinates of single points thanks to conflicting standards.
                // see: https://gis.stackexchange.com/questions/246102/leaflet-reads-geojson-x-y-as-y-x-how-can-i-correct-this
                if (parsedGeoJson.type === 'Point') {
                    parsedGeoJson = { ...parsedGeoJson } as Point;
                    (parsedGeoJson as Point).coordinates.reverse();
                }
                const elementBounds = L.geoJSON(parsedGeoJson).getBounds();
                return accumulator
                    ? accumulator.extend(elementBounds)
                    : elementBounds;
            }
            return accumulator;
        },
        null,
    );

    // return Switzerland as bounding box if no information is available
    const longLatBoundsSwitzerland = new L.LatLngBounds(
        new L.LatLng(45.6755, 5.8358),
        new L.LatLng(47.9163, 10.9793),
    );

    return mergedBounds ? mergedBounds : longLatBoundsSwitzerland;
}
