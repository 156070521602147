import moment from 'moment';
import { getOrElse, isDefined, Maybe } from '../lib/MaybeV2';
import * as Date from '../lib/Date';
import * as PermissionTag from './PermissionTag';
import { TomPaymentType } from './Tom';

export enum ConfigurationSpecificationState {
    planning,
    released,
    invalidated,
}

export type TomConfigurationChangeType =
    | 'SPACES'
    | 'ZONE'
    | 'OUT_OF_SERVICE'
    | 'BACK_IN_SERVICE'
    | 'PARAMETER'
    | 'COINS'
    | 'PERMISSIONS'
    | 'DUPLICATE'
    | 'OTHER';

export interface TomConfiguration {
    configurationId: number;
    tomId: number;
    validFrom: moment.Moment;
    state: ConfigurationSpecificationState;
    lastWrite?: Maybe<moment.Moment>;
    editable: boolean;
    tags: PermissionTag.PermissionTag[];
    configuration: ConfigurationData;
    releasingAdminName?: string | null;
}

interface TomConfigurationUnparsed
    extends Omit<
        TomConfiguration,
        'validFrom' | 'lastWrite' | 'configuration' | 'state'
    > {
    validFrom: string;
    lastWrite?: string;
    configuration: ConfigurationDataUnparsed;
    specificationStatus: string;
}

export interface ConfigurationData {
    // tariff
    zoneId: number;
    // operation status
    isOutOfService: boolean;
    outOfServiceTitle: string;
    outOfServiceBody: string;
    // parking
    gracePeriodInMinutes: number;
    maxNegativeTimeInMinutes: number;
    isExpirationTimeVisible: boolean;
    paymentTransactionPeriodInMinutes: number;
    // permission
    permissionTags: number[];
    // ui
    defaultLanguage: string;
    // alarm
    batteryLowVoltageInMilliVolts: number;
    cashBoxFullAlarmThresholdInRappen: number;
    // hardware
    isMainsPowered: boolean;
    isCollectionTubeSensorAvailable: boolean;
    isCommunicationModuleAvailable: boolean;
    // spaces
    spaces: ParkingSpace[];
    // coins
    acceptedCoinCodes: string[];
    // communication
    apn: string;
    isSpaceStatusTransmissionEnabled: boolean;
    spaceStatusIntervalInMinutes: number;
    // change tracking
    changeTypes: TomConfigurationChangeType[];
    changeComment: string;

    paymentType: TomPaymentType;
    communicationTimeoutInSeconds: number;
    isReceiptEnabled: boolean;
    receiptUrl: string;

    // energy saving
    isEnergySavingEnabled: boolean;
    heartbeatIntervalInSeconds: number;
    energySavingHeartbeatIntervalInSeconds: number;
    energySavingIsCommunicationModuleShutdownOnIdleEnabled: boolean;
    energySavingStartTimeOfDay: string;
    energySavingEndTimeOfDay: string;
    backlight24hActivated: boolean;
    // NOTE: tariffs are not part of configuration but are joined when generating the config file
}

interface ConfigurationDataUnparsed
    extends Omit<
        ConfigurationData,
        | 'outOfServiceTitle'
        | 'outOfServiceBody'
        | 'apn'
        | 'isSpaceStatusTransmissionEnabled'
        | 'spaceStatusIntervalInMinutes'
        | 'changeComment'
    > {
    outOfServiceTitle?: string;
    outOfServiceBody?: string;
    apn?: string;
    isSpaceStatusTransmissionEnabled?: boolean;
    spaceStatusIntervalInMinutes?: number;
    changeComment?: string;
}

// TODO TOM-110: remove zoneId logic and replace with simple flag to indicate multiple zones in configuration
export interface ParkingSpace {
    enabled: boolean;
    label: string | null;
    zoneId: number | null;
}

export function parseTomConfiguration(
    t: TomConfigurationUnparsed,
): TomConfiguration {
    return Object.assign(t, {
        configurationId: t.configurationId,
        tomId: t.tomId,
        validFrom: moment(t.validFrom),
        state: ConfigurationSpecificationState[
            t.specificationStatus as keyof typeof ConfigurationSpecificationState
        ],
        lastWrite: isDefined(t.lastWrite)
            ? Date.Parser.isoToMaybeMoment(t.lastWrite)
            : null,
        editable: t.editable,
        tags: t.tags,
        configuration: parseTomConfigurationData(t.configuration),
    });
}

export function parseTomConfigurationData(
    c: ConfigurationDataUnparsed,
): ConfigurationData {
    return {
        ...c,
        // default values that can be null to empty strings
        outOfServiceTitle: getOrElse(c.outOfServiceTitle, ''),
        outOfServiceBody: getOrElse(c.outOfServiceBody, ''),

        apn: getOrElse(c.apn, 'em'),
        isSpaceStatusTransmissionEnabled: getOrElse(
            c.isSpaceStatusTransmissionEnabled,
            true,
        ),
        spaceStatusIntervalInMinutes: getOrElse(
            c.spaceStatusIntervalInMinutes,
            30,
        ),

        changeComment: getOrElse(c.changeComment, ''),
    };
}
