import moment from 'moment';
import * as LicensePlateType from 'dg-web-shared/dto/LicensePlateType.ts';
import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    getOrNull,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { shallowEqual } from 'dg-web-shared/lib/Object.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import { PaymentChannel } from '../../common/models/PaymentChannel.ts';
import { AdditionalInfoMode } from '../../common/state/PermitTypeState.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import * as PermitDetailActions from '../actions/PermitDetailActions.tsx';
import { renderPrice } from '../components/Shared.tsx';
import { PermitAddress } from './AddressState.ts';
import {
    Overlap,
    OverlapUnparsed,
    parseBadgesThatNeedWhitelist,
    parseLpsThatNeedWhitelist,
    parseOverlaps,
} from './SharedParseLogic.ts';

export interface LicensePlate {
    id: number;
    licensePlateNr: string;
    type: LicensePlateType.LicensePlateType;
    country: string;
}

export interface Badge {
    id: number;
    badgeLabelNr: string;
}

export interface Zone {
    id: number;
    name: string;
    zipCode: string;
    city: string;
    offstreet: boolean;
}

export interface OnstreetZone extends Zone {
    extZoneCode: number;
    offstreet: false;
}

export interface OffstreetZone extends Zone {
    offstreet: true;
}

interface Customer {
    id: number;
    customerNr: number;
    isLocked: boolean;
    accountTerminatedAt: string | null;
}

interface Refund {
    refundedAt: string;
    refundAmountRappen: number;
    refundedBy: string;
}

export interface Permit {
    id: number;
    operatorId: number;
    validFrom: string;
    validTo: string;
    additionalInfo: string | null;
    permittypeAdditionalInfoMode: AdditionalInfoMode;
    paid: boolean;
    creationDate: string;
    adminId: number | null;
    paidByCustomer: number | null;
    price: number;
    priceUnit: DurationType | null;
    channel: string;
    licensePlates: LicensePlate[];
    badges: Badge[];
    onstreetZones: OnstreetZone[];
    offstreetZones: OffstreetZone[];
    permitTypeId: number;
    permitTypeDescription: string;
    prepaidhistoryId: number | null;
    outpaymenthistoryId: number | null;
    onstreetZoneType: OnstreetZoneType;
    maxLicensePlates: number;
    remark: string;
    vignetteSideprint: LicensePlate[];
    vignetteInvoiced: number | null;
    refunded: boolean;
    renewable: boolean;
    address: PermitAddress | null;
    customer: Customer | null;
    refund: Refund | null;
    priceModifier: string | null;
    counterPaymentChannel: Http.OperatorAccount.Permits.CounterPaymentChannel | null;
    paymentChannel: PaymentChannel;
    externalPayment: boolean;
    activatablePermitType: boolean;
}

export enum OnstreetZoneType {
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    FIXED_ZONES = 'FIXED_ZONES',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
}

export type SubstitutedContractRight = {
    contractRightId: number;
    substitutedAt: string;
    createdAt: string;
    licensePlates: LicensePlate[];
    badges: ServerSideBadge[];
    onstreetZones: OnstreetZone[];
    offstreetZones: OffstreetZone[];
};

type ServerSideBadge = {
    id: number;
    labelNr: string;
};

export type VersionBrowsingMode =
    | VersionBrowsingInactive
    | VersionBrowsingLatest
    | VersionBrowsingOlder;

type VersionBrowsingInactive = { tag: VersionBrowsingModeTag.INACTIVE };
type VersionBrowsingLatest = { tag: VersionBrowsingModeTag.LATEST };
type VersionBrowsingOlder = {
    tag: VersionBrowsingModeTag.OLDER;
    oldVersionIndex: number;
};

export enum VersionBrowsingModeTag {
    INACTIVE = 'INACTIVE',
    LATEST = 'LATEST',
    OLDER = 'OLDER',
}

export const permitHasAccount = (p: Permit) => !!p.paidByCustomer;

export const permitAlreadyRefunded = (p: Permit) => p.refunded;

export const permitHasCustomer = (p: Permit) => !!p.customer;

export const permitAccountLocked = (p: Permit) =>
    !!p.customer && p.customer.isLocked;

const permitHasBookedRefund = (p: Permit) =>
    !!p.prepaidhistoryId || !!p.outpaymenthistoryId;

export const isPermitRefundBookable = (p: Permit) => {
    // we allow refunds for all permits for luzern and write it into comments.
    if (p.operatorId === 93) {
        return true;
    }
    if (
        [PaymentChannel.TWINT, PaymentChannel.QUICKCHECKOUT].includes(
            p.paymentChannel,
        ) &&
        !permitHasBookedRefund(p)
    ) {
        return true;
    }
    return (
        permitHasAccount(p) &&
        !permitAlreadyRefunded(p) &&
        !permitHasBookedRefund(p) &&
        !permitAccountLocked(p) &&
        permitHasCustomer(p)
    );
};

export const getCustomerAccountString = (p: Permit) =>
    thenElse(p.customer, c => c.customerNr.toString(), '');

export const getPermitPrice = (
    permit: Permit,
    refundedNote: (amount: string, refundDate: string) => string,
    language: string,
) =>
    renderPrice(permit, language) +
    thenElse(
        permit.refund,
        r =>
            ' ' +
            refundedNote(
                currencyCentsToLocalPrice(language, r.refundAmountRappen),
                Formatter.dayMonthYear(Parser.isoToMoment(r.refundedAt)),
            ),
        '',
    );

export namespace Server {
    const parseLicensePlate = (lp: LicensePlate): LicensePlate => {
        return {
            id: lp.id,
            licensePlateNr: lp.licensePlateNr,
            type: lp.type,
            country: lp.country,
        };
    };

    const parseBadge = (b: ServerSideBadge): Badge => {
        return {
            id: b.id,
            badgeLabelNr: b.labelNr,
        };
    };

    const parsePermit = (p: Permit): Permit => {
        return {
            id: p.id,
            operatorId: p.operatorId,
            validFrom: p.validFrom,
            validTo: p.validTo,
            additionalInfo: p.additionalInfo,
            permittypeAdditionalInfoMode: p.permittypeAdditionalInfoMode,
            paid: p.paid,
            creationDate: p.creationDate,
            adminId: p.adminId,
            paidByCustomer: p.paidByCustomer,
            price: p.price,
            priceUnit: p.priceUnit,
            channel: p.channel,
            licensePlates: p.licensePlates.map((lp: LicensePlate) =>
                parseLicensePlate(lp),
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            badges: p.badges.map((b: any) => parseBadge(b)),
            onstreetZones: p.onstreetZones,
            offstreetZones: p.offstreetZones,
            permitTypeId: p.permitTypeId,
            permitTypeDescription: p.permitTypeDescription,
            prepaidhistoryId: p.prepaidhistoryId,
            outpaymenthistoryId: p.outpaymenthistoryId,
            onstreetZoneType: p.onstreetZoneType,
            maxLicensePlates: p.maxLicensePlates,
            remark: p.remark,
            vignetteSideprint: p.vignetteSideprint || [],
            vignetteInvoiced: p.vignetteInvoiced,
            refunded: p.refunded,
            renewable: p.renewable,
            address: p.address,
            customer: p.customer,
            refund: p.refund,
            priceModifier: p.priceModifier,
            counterPaymentChannel: p.counterPaymentChannel,
            paymentChannel: p.paymentChannel,
            externalPayment: p.externalPayment,
            activatablePermitType: p.activatablePermitType,
        };
    };

    const sideEffects = (store: Flux.Store, state: State) => {
        const permitId = new ClearancePermitListState.StateSlice(store).state
            .selectedPermitId;
        if (state.shouldFetch && !!permitId) {
            store.update(PermitDetailActions.receivePermitDetail, permitId);
        }
    };

    export type State = ServerStateSlice.ServerState<Maybe<Permit>>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<Maybe<Permit>>(
            'permit-detail-PermitDetailState',
            () => null,
            sideEffects,
            parsePermit,
        );
}

export namespace Edit {
    export enum Action {
        terminate = 'terminate',
        storno = 'storno',
        refund = 'refund',
    }

    export interface State {
        action?: Action;
        to?: moment.Moment;
        additionalInfo?: string;
        remark?: string;
        licensePlates?: LicensePlate[];
        badges?: Badge[];
        address?: Partial<PermitAddress>;
        addressId?: number;
        onstreetZones?: OnstreetZone[];
        offstreetZones?: OffstreetZone[];
    }

    const s = Flux.generateState<State>('permit-detail-PermitSlideInState', {});

    export const get = s.get;
    export const set = s.set;
    export const reset = s.reset;

    export const stateWrite = (store: Flux.Store, state: State): string => {
        const oldState = get(store);
        const name = s.stateWrite(store, state);

        if (hasNonTrivialEdits(state, oldState)) {
            Validation.reset(store);
        }

        return name;
    };
}

export namespace Terminate {
    export interface State {
        terminateDate?: Maybe<moment.Moment>;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'permit-detail-Terminate',
        {},
    );
}

export namespace Refund {
    export interface State {
        amount: string | null;
        remark: string | null;
        doRefund: boolean;
        confirmPressed: boolean;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'permit-detail-Refund',
        {
            amount: null,
            remark: null,
            doRefund: false,
            confirmPressed: false,
        },
    );
}

export namespace PermitCancellationResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'permit-detail-PermitCancellationResponse',
        () => null,
    );
}

export const editsHaveBeenMade = (
    edit: Edit.State,
    server: Server.State,
): boolean => {
    if (server.data) {
        const infoHaveChanged =
            edit.additionalInfo !== undefined &&
            edit.additionalInfo !== server.data.additionalInfo;
        const remarksHaveChanged =
            edit.remark !== undefined && edit.remark !== server.data.remark;

        return (
            hasNonTrivialEdits(edit, {
                address: server.data.address || undefined,
            }) ||
            !!edit.to ||
            infoHaveChanged ||
            remarksHaveChanged
        );
    } else {
        return false;
    }
};

const hasNonTrivialEdits = (
    edit: Edit.State,
    server: Edit.State | undefined,
): boolean => {
    if (server) {
        const addressHasChanged =
            !!edit.address &&
            !shallowEqual(getOrElse(server.address, {}), {
                ...getOrNull(server.address),
                ...edit.address,
            });

        return (
            !!edit.to ||
            !!edit.badges ||
            !!edit.licensePlates ||
            !!edit.onstreetZones ||
            !!edit.offstreetZones ||
            addressHasChanged ||
            !!edit.addressId
        );
    } else {
        return false;
    }
};

enum IncompleteData {
    noLicensePlates,
}

export function zoneIds(...zones: Array<Zone[] | undefined>): number[] | null {
    return mergeZones(...zones)?.map(z => z.id) ?? null;
}

function mergeZones(...zones: Array<Zone[] | undefined>): Zone[] | null {
    const filteredZones = zones.filter((z): z is Zone[] => z !== undefined);
    if (filteredZones.length === 0) {
        return null;
    }
    return filteredZones.flat();
}

const incompleteData = (edit: Edit.State): IncompleteData[] => {
    const lps = edit.licensePlates;
    const badges = edit.badges;
    const zones = mergeZones(edit.onstreetZones, edit.offstreetZones);

    if (!!lps && lps.length === 0) {
        return [IncompleteData.noLicensePlates];
    } else if (!!badges && badges.length === 0) {
        return [IncompleteData.noLicensePlates];
    } else if (!!zones && zones.length === 0) {
        return [IncompleteData.noLicensePlates];
    } else {
        return [];
    }
};

const makeUnvalidatedEditPayload = (
    edit: Edit.State,
    server: Server.State,
    response: EditResponse.State,
): Maybe<Http.OperatorAccount.Permits.Changes> => {
    if (!editsHaveBeenMade(edit, server)) {
        return null;
    }
    if (incompleteData(edit).length > 0) {
        return null;
    }
    if (response.pending) {
        return null;
    }
    return {
        to: thenElse(edit.to, to => Formatter.isoYearMonthDay(to), undefined),
        additionalInfo: edit.additionalInfo,
        remark: edit.remark,
        licensePlateIds: thenElse(
            edit.licensePlates,
            lps => lps.map(lp => lp.id),
            undefined,
        ),
        badgeIds: thenElse(
            edit.badges,
            badges => badges.map(badge => badge.id),
            undefined,
        ),
        zoneIds: zoneIds(edit.onstreetZones, edit.offstreetZones) ?? undefined,
        address: edit.address,
        addressId:
            !!edit.address && !!server.data && !!server.data.address
                ? null
                : edit.addressId,
    };
};

export const makeEditPayload = (
    edit: Edit.State,
    server: Server.State,
    response: EditResponse.State,
    validation: Validation.State,
): Maybe<Http.OperatorAccount.Permits.Changes> => {
    if (validation.pending) {
        return null;
    }
    if (!validation.data) {
        return null;
    }
    if (validation.data.lpsThatNeedWhitelist.length > 0) {
        return null;
    }
    if (validation.data.overlappingLpPermits.length > 0) {
        return null;
    }
    if (validation.data.badgesThatNeedAccount.length > 0) {
        return null;
    }
    return makeUnvalidatedEditPayload(edit, server, response);
};

export namespace Validation {
    export interface ValidationData {
        overlappingLpPermits: Overlap[];
        overlappingBadgePermits: Overlap[];
        lpsThatNeedWhitelist: LicensePlate[];
        badgesThatNeedWhitelist: Badge[];
        badgesThatNeedAccount: string[];
    }

    export interface ValidationDataUnparsed
        extends Omit<
            ValidationData,
            | 'overlappingLpPermits'
            | 'overlappingBadgePermits'
            | 'lpsThatNeedWhitelist'
            | 'badgesThatNeedWhitelist'
        > {
        overlappingLpPermits: OverlapUnparsed[];
        overlappingBadgePermits: OverlapUnparsed[];
        lpsThatNeedWhitelist: LicensePlate[];
        badgesThatNeedWhitelist: Badge[];
        badgesThatNeedAccount: string[];
    }

    export type State = ServerStateSlice.ServerState<Maybe<ValidationData>>;

    const sideEffects = (store: Flux.Store, state: State): void => {
        const selectedPermit = new ClearancePermitListState.StateSlice(store)
            .state.selectedPermitId;
        const payload = makeUnvalidatedEditPayload(
            Edit.get(store),
            Server.get(store),
            EditResponse.get(store),
        );
        if (!!selectedPermit && !!payload && state.shouldFetch) {
            store.update(validateEdit, {
                id: selectedPermit,
                changes: payload,
            });
        }
    };

    const parseBody = (body: ValidationDataUnparsed): ValidationData => {
        return {
            overlappingLpPermits: parseOverlaps(body.overlappingLpPermits),
            overlappingBadgePermits: parseOverlaps(
                body.overlappingBadgePermits,
            ),
            lpsThatNeedWhitelist: parseLpsThatNeedWhitelist(
                body.lpsThatNeedWhitelist,
            ),
            badgesThatNeedWhitelist: parseBadgesThatNeedWhitelist(
                body.badgesThatNeedWhitelist,
            ),
            badgesThatNeedAccount: body.badgesThatNeedAccount,
        };
    };

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<Maybe<ValidationData>>(
            'permit-detail-EditValidation',
            () => null,
            sideEffects,
            parseBody,
        );

    const validateEdit = AsyncRequest.request(
        Http.OperatorAccount.Permits.editValidation,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'PermitEditState-validation-log-ignore';
        },
    );
}

export namespace EditResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'permit-detail-EditResponse',
        () => null,
    );
}

export namespace Layout {
    export interface State {
        showPrintSlideIn: boolean;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'permit-detail-EditLayout',
        { showPrintSlideIn: false },
    );
}

export const resetAllStates = (store: Flux.Store) => {
    Server.reset(store);
    Validation.reset(store);
    Edit.reset(store);
    EditResponse.reset(store);
    Layout.reset(store);
};
