import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as Http from '../api/Http.ts';
import { Localized } from '../common/components/Localized.tsx';
import * as Text from '../common/i18n/Text.ts';
import { Translations } from '../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../common/state/CurrentOperatorLoginState.ts';
import { ButtonSpecialState } from '../ui/buttons/IconButton.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownLinkItem,
    ButtonDropdownSeparator,
} from '../ui/slidein/ButtonDropdown.tsx';
import {
    ConfirmationHeader,
    ErrorHeader,
    HalfSlideIn,
    LoaderHeader,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderButtonsContainer,
    SlideInHeaderTexts,
} from '../ui/slidein/Slidein.tsx';
import { DateTime } from 'luxon';
import {
    OnstreetTransaction,
    OnstreetTransactionChannel,
} from './OnstreetTransactionModel.ts';
import {
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Dispatch, SetStateAction, useState } from 'react';
import { OnstreetTransactionDetailSlideInBody } from './OnstreetTransactionDetailSlideInBody.tsx';

export function TransactionDetailSlideIn({
    transaction,
    refetchTransactionList,
    language,
    onClose,
}: {
    transaction: OnstreetTransaction | null;
    refetchTransactionList: () => void;
    language: string;
    onClose: () => void;
}) {
    return (
        <HalfSlideIn open={Boolean(transaction)}>
            {transaction && (
                <TransactionDetailContent
                    transaction={transaction}
                    refetchTransactionList={refetchTransactionList}
                    language={language}
                    onClose={onClose}
                />
            )}
        </HalfSlideIn>
    );
}

function TransactionDetailContent({
    transaction,
    refetchTransactionList,
    language,
    onClose,
}: {
    transaction: OnstreetTransaction;
    refetchTransactionList: () => void;
    language: string;
    onClose: () => void;
}) {
    const [refundInputState, setRefundInputState] =
        useState<RefundInputValues | null>(null);
    const [refundRequestState, refund] = useServerWrite<
        RefundRequestPayload,
        null
    >(() => ({
        url: `/ui-api/operator-account/park-transaction/refund`,
    }));

    useServerSuccessEffect(refundRequestState, () => {
        setRefundInputState(null);
        refetchTransactionList();
    });

    return (
        <>
            <SlideInHeader
                transaction={transaction}
                language={language}
                refundInputState={refundInputState}
                setRefundInputState={setRefundInputState}
                refundRequestState={refundRequestState}
                onClose={onClose}
                onRefundSubmit={submitData =>
                    refund({
                        contractId: submitData.contractId,
                        amountRappen: submitData.amount
                            ? Math.round(parseFloat(submitData.amount) * 100)
                            : 0,
                        remark: submitData.remark,
                    })
                }
            />
            <SlideInBody>
                <OnstreetTransactionDetailSlideInBody
                    transaction={transaction}
                    language={language}
                    refundInputState={refundInputState}
                    setRefundInputState={setRefundInputState}
                />
            </SlideInBody>
        </>
    );
}

export type RefundInputValues = {
    contractId: number;
    amount: string;
    remark: string;
};

export type RefundRequestPayload = {
    contractId: number;
    amountRappen: number;
    remark: string;
};

function SlideInHeader({
    transaction,
    refundInputState,
    setRefundInputState,
    refundRequestState,
    language,
    onClose,
    onRefundSubmit,
}: {
    transaction: OnstreetTransaction;
    refundInputState: RefundInputValues | null;
    setRefundInputState: Dispatch<SetStateAction<RefundInputValues | null>>;
    refundRequestState: ServerRequestState<null, object>;
    language: string;
    onClose: () => void;
    onRefundSubmit: (refundData: RefundInputValues) => void;
}) {
    const { storeState } = useStore(store => ({
        currentOperatorLogin: CurrentOperatorLoginState.get(store),
    }));

    const hasRightToRefundParkTransactions =
        !storeState.currentOperatorLogin.pending &&
        storeState.currentOperatorLogin.data &&
        storeState.currentOperatorLogin.data.permissions.refundParkTransactions;

    const texts = transactionDetailSlideInTexts[language];
    if (refundRequestState.status === RequestStatus.PENDING) {
        return <LoaderHeader title={texts.loadingCaption()} />;
    } else if (refundRequestState.status === RequestStatus.ERROR) {
        return (
            <ErrorHeader
                language={language}
                title={texts.errorHeader(
                    refundRequestState.httpStatusCode || 0,
                )}
                subtitle={texts.errorCaption()}
                onCancel={onClose}
            />
        );
    } else if (refundRequestState.status === RequestStatus.SUCCESS) {
        return (
            <SecondLevelHeader onClose={onClose}>
                <SlideInHeaderTexts
                    title={texts.successHeader()}
                    hasLeftIcon={false}
                />
            </SecondLevelHeader>
        );
    } else if (refundInputState != null) {
        return (
            <ConfirmationHeader
                language={language}
                title={texts.confirmationHeader()}
                onCancel={() => setRefundInputState(null)}
                onConfirm={() => {
                    if (isValidCreditAmount(transaction, refundInputState)) {
                        onRefundSubmit(refundInputState);
                    }
                }}
                confirmButtonSpecialState={
                    !isValidCreditAmount(transaction, refundInputState)
                        ? ButtonSpecialState.DISABLED
                        : null
                }
            />
        );
    } else {
        return (
            <SecondLevelHeader onClose={onClose}>
                <SlideInHeaderTexts
                    title={texts.slideInHeader()}
                    hasLeftIcon={false}
                />
                {hasRightToRefundParkTransactions &&
                !transaction.refund &&
                paymentChannelIsRefundable(transaction.channel) ? (
                    <SlideInHeaderButtonsContainer>
                        <ButtonDropdown label={texts.editLabel()}>
                            <ButtonDropdownLinkItem
                                label={texts.printDocumentActionButtonTooltip()}
                                href={Http.operatorAppApiUrl(
                                    `/park-transaction/${transaction.id}/receipt`,
                                )}
                            />
                            {transaction.price > 0 && (
                                <>
                                    <ButtonDropdownSeparator />
                                    <RefundMenuItem
                                        transaction={transaction}
                                        onRefundInit={() =>
                                            setRefundInputState({
                                                contractId: transaction.id,
                                                amount: (
                                                    transaction.maxRefundableAmount ??
                                                    transaction.price
                                                ).toString(),
                                                remark: '',
                                            })
                                        }
                                    />
                                </>
                            )}
                        </ButtonDropdown>
                    </SlideInHeaderButtonsContainer>
                ) : null}
            </SecondLevelHeader>
        );
    }
}

function isValidCreditAmount(
    transaction: OnstreetTransaction,
    inputFields: RefundInputValues,
) {
    const amount = inputFields.amount;

    if (amount) {
        const a = parseFloat(amount);
        if (!transaction.maxRefundableAmount) {
            throw 'Logic error, maxRefundableAmount must be set';
        }
        return !isNaN(a) && a > 0 && a <= transaction.maxRefundableAmount;
    }

    return false;
}

function paymentChannelIsRefundable(
    paymentChannel: OnstreetTransactionChannel,
): boolean {
    switch (paymentChannel) {
        case OnstreetTransactionChannel.TWINT:
        case OnstreetTransactionChannel.Parkingpay:
            return true;
        case OnstreetTransactionChannel.EasyPark:
        case OnstreetTransactionChannel.Pnrail:
            return false;
    }
}

function RefundMenuItem({
    transaction,
    onRefundInit,
}: {
    transaction: OnstreetTransaction;
    onRefundInit: () => void;
}) {
    if (transaction.customerAccountTerminated) {
        return (
            <ButtonDropdownItem
                disabled={true}
                label={
                    <Localized
                        de="Rückzahlung nicht möglich: Benutzerkonto wurde gekündigt"
                        fr="Remboursement impossible: le compte utilisateur a été annulé"
                        it="Rimborso non possibile: il conto utente è stato annullato"
                        en="Refund not possible: customer account was canceled"
                    />
                }
                onClick={() => {}}
            />
        );
    }

    return (
        <ButtonDropdownItem
            disabled={
                !!transaction.refund ||
                transaction.maxRefundableAmount == null ||
                !transaction.tend ||
                !expiredForRefund(transaction.tend)
            }
            label={
                <Localized
                    de="Rückzahlung"
                    fr="Remboursement"
                    it="Rimborso"
                    en="Refund"
                />
            }
            onClick={onRefundInit}
        />
    );
}

interface TransactionDetailSlideInTexts {
    slideInHeader: Text.Translation;
    editLabel: Text.Translation;
    printDocumentActionButtonTooltip: Text.Translation;
    successHeader: Text.Translation;
    errorHeader: Text.TranslationWithArgs1<number>;
    errorCaption: Text.Translation;
    loadingCaption: Text.Translation;
    confirmationHeader: Text.Translation;
}

export const transactionDetailSlideInTexts: Translations<TransactionDetailSlideInTexts> =
    {
        de: {
            editLabel: () => 'Bearbeiten',
            confirmationHeader: () => 'Parkvorgang zurückzahlen?',
            errorCaption: () =>
                'Das für die Transaktion verwendete Zahlungsmittel ist nicht mehr verfügbar, weshalb eine Rückerstattung nicht vorgenommen werden kann.',
            errorHeader: httpStatus => `HTTP ${httpStatus}`,
            loadingCaption: () => 'Rückzahlung wird ausgeführt',
            printDocumentActionButtonTooltip: () => 'Beleg anzeigen',
            slideInHeader: () => 'Parkvorgang',
            successHeader: () => 'Parkvorgang erfolgreich zurückbezahlt',
        },
        fr: {
            editLabel: () => 'Modifier',
            confirmationHeader: () => 'Rembourser procédure de stationnement?',
            errorCaption: () =>
                "Le moyen de paiement utilisé pour la transaction n'est plus disponible, c'est pourquoi un remboursement ne peut être effectué.",
            errorHeader: httpStatus => `HTTP ${httpStatus}`,
            loadingCaption: () => 'Le remboursement va être executé',
            printDocumentActionButtonTooltip: () => 'Afficher document',
            slideInHeader: () => 'Procédure de stationnement',
            successHeader: () => 'La procédure a été remboursée',
        },
        it: {
            editLabel: () => 'Modificare',
            confirmationHeader: () => 'Rimborsare procedura di parcheggio?',
            errorCaption: () =>
                'Il mezzo di pagamento utilizzato per la transazione non è più disponibile, per questo non è possibile effettuare il rimborso.',
            errorHeader: httpStatus => `HTTP ${httpStatus}`,
            loadingCaption: () => 'Il rimborso viene eseguito',
            printDocumentActionButtonTooltip: () => 'Mostra documento',
            slideInHeader: () => 'Procedura di parcheggio',
            successHeader: () => 'La procedura è stata rimborsata',
        },
        en: {
            editLabel: () => 'Edit',
            confirmationHeader: () => 'Refund parking transaction?',
            errorCaption: () =>
                'The payment method used for the transaction is no longer available, so a refund cannot be processed.',
            errorHeader: httpStatus => `HTTP ${httpStatus}`,
            loadingCaption: () => 'Processing refund',
            printDocumentActionButtonTooltip: () => 'View receipt',
            slideInHeader: () => 'Parking Transaction',
            successHeader: () => 'Parking transaction successfully refunded',
        },
    };

function expiredForRefund(validTo: string) {
    return DateTime.fromISO(validTo) >= DateTime.now().minus({ months: 3 });
}
