import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import {
    Body,
    ColumnContent,
    Container,
    LeftColumn,
    RightColumn,
} from '../ui/layout/SingleContent.tsx';
import * as CurrentOperatorLoginState from '../common/state/CurrentOperatorLoginState.ts';
import moment from 'moment';
import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';

interface SpecialReportsState {
    settings: SettingsState.State;
    currentLogin: CurrentOperatorLoginState.State;
}

export class SpecialReports extends Flux.Container<SpecialReportsState> {
    stateSelector(): SpecialReportsState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    render() {
        const years = [
            moment().year(),
            moment().year() - 1,
            moment().year() - 2,
        ];
        return (
            <Container>
                <Body disabled={false} slideIns={[]}>
                    <ColumnContent>
                        <LeftColumn>
                            {years.map(y => {
                                return (
                                    <a
                                        key={y}
                                        className={css({
                                            color: ColorHex.typo,
                                            display: 'block',
                                            marginBottom: '8px',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: ColorHex.lightblue,
                                            },
                                        })}
                                        target="_blank"
                                        href={
                                            `/ui-api/operator-account/park-transactions/exports/mobility-report-csv?` +
                                            `from=${y}-01-01&to=${y}-12-31`
                                        }
                                        rel="noreferrer"
                                    >
                                        {'Mobilitätsauswertung ' + y}
                                    </a>
                                );
                            })}
                        </LeftColumn>
                        <RightColumn />
                    </ColumnContent>
                </Body>
            </Container>
        );
    }
}
