import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import moment from 'moment';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    parseTomCollection,
    TomCollection,
} from 'dg-web-shared/model/TomCollection.ts';

export namespace List {
    export type Data = { data: TomCollection[]; size: number };
    export type State = ServerStateSlice.ServerState<Data>;

    const generateCollectionsState = (
        key: string,
        shouldFetch: (s: Flux.Store) => boolean,
        fetcher: (s: Flux.Store) => Flux.Update<object>,
    ) =>
        ServerStateSlice.generateServerState<Data>(
            key,
            () => ({ data: [], size: 0 }),
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch && shouldFetch(store)) {
                    store.update(fetcher(store));
                }
            },
            b => ({
                size: b.size,
                data: b.data.map(parseTomCollection),
            }),
        );

    const fetchCollections = (s: Flux.Store): Flux.Update<object> => {
        return AsyncRequest.request(
            () =>
                Http.OperatorAccount.TomCollections.get({
                    validFrom: Filter.get(s).validFrom,
                    validTo: Filter.get(s).validTo,
                    toms: Filter.get(s).toms,
                    sticks: Filter.get(s).sticks,
                    hideEmptyCollections: Filter.get(s).hideEmptyCollections,
                }),
            setResponse,
        );
    };

    export const { get, reset, setResponse, forceRefetch } =
        generateCollectionsState(
            'tom-collections-List',
            s =>
                isDefined(Filter.get(s).validFrom) ||
                isDefined(Filter.get(s).validTo) ||
                Filter.get(s).toms.length > 0 ||
                Filter.get(s).sticks.length > 0,
            fetchCollections,
        );
}

export namespace Filter {
    export interface State {
        filterSlideInVisible: boolean;

        validFrom: Maybe<moment.Moment>;
        validTo: Maybe<moment.Moment>;
        validDateSelectionVisible: boolean;

        toms: number[];
        sticks: number[];
        tomSelectionVisible: boolean;
        sticksSelectionVisible: boolean;
        hideEmptyCollections: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'tom-collections-Filter',
        {
            filterSlideInVisible: false,
            // show collections of last and this month by default
            validFrom: moment().startOf('isoWeek'),
            validTo: null,
            validDateSelectionVisible: false,

            toms: [],
            sticks: [],
            tomSelectionVisible: false,
            sticksSelectionVisible: false,
            hideEmptyCollections: true,
        },
    );

    export const dateFilterActive = (s: State): boolean =>
        isDefined(s.validFrom) || isDefined(s.validTo);

    export const tomFilterActive = (s: State): boolean => s.toms.length > 0;

    export const sticksFilterActive = (s: State): boolean =>
        s.sticks.length > 0;

    export const emptyCollectionsFilterActive = (s: State): boolean =>
        s.hideEmptyCollections;

    // hideEmptyCollections is not part of filter activation. (It does not filter enough...)
    export const filterActive = (s: State): boolean =>
        dateFilterActive(s) || tomFilterActive(s);
}

export namespace Detail {
    export interface State {
        selectedCollection: Maybe<TomCollection>;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'tom-collections-Detail',
        { selectedCollection: undefined },
    );
}
