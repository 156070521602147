import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import { isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { TariffData } from 'dg-web-shared/model/Tariff.ts';

export namespace List {
    export type State = ServerStateSlice.ServerState<TariffData[]>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<TariffData[]>(
            'TariffState-Tariffs',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchTariffs);
                }
            },
            (body: TariffData[] | null) => {
                if (isUndefined(body)) {
                    return [];
                } else {
                    return body;
                }
            },
        );

    const fetchTariffs = AsyncRequest.request(
        Http.OperatorAccount.Tariffs.get,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'TariffState-fetchTariffs';
        },
    );

    export function getTariffById(
        tariffs: TariffData[],
        tariffId: number | null | undefined,
    ): Maybe<TariffData> {
        for (const tariff of tariffs) {
            if (tariff.tariffId === tariffId) {
                return tariff;
            }
        }
        return null;
    }
}

export namespace Layout {
    export interface State {
        selectedTariffId?: Maybe<number>;
        dateSelectionOpen: boolean;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'TariffState-Layout',
        {
            dateSelectionOpen: false,
        },
    );
}

export namespace Calculator {
    export interface State {
        date?: moment.Moment | null;
        time?: string | null;
        amount?: string | null;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'TariffState-Calculator',
        {},
    );
}
