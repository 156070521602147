export enum CloudConnectorPeripheryType {
    ENTRY = 'ENTRY',
    EXIT = 'EXIT',
    PAY_STATION = 'PAY_STATION',
    TRANSIT_ENTRY = 'TRANSIT_ENTRY',
    TRANSIT_EXIT = 'TRANSIT_EXIT',
    ACCESS = 'ACCESS',
}

type CloudConnectorPeripheryShared = {
    type: CloudConnectorPeripheryType;
    onPremiseId: number;
    zoneId: number;
    name: string;
    createdAt: string;
    lastSeenAt: string;
    softwareVersion: string | null;
    torizonDeviceId: string | null;
    lastConnectedCloudConnectorId: string | null;
};

export type EntryPeriphery = CloudConnectorPeripheryShared & {
    type: CloudConnectorPeripheryType.ENTRY;
};

export type ExitPeriphery = CloudConnectorPeripheryShared & {
    type: CloudConnectorPeripheryType.EXIT;
};

export type PayStationPeriphery = CloudConnectorPeripheryShared & {
    type: CloudConnectorPeripheryType.PAY_STATION;
    cashless: boolean;
};

type TransitPeriphery = CloudConnectorPeripheryShared & {
    zoneBarrierGateSubzoneId: number | null;
};

export type TransitEntryPeriphery = TransitPeriphery & {
    type: CloudConnectorPeripheryType.TRANSIT_ENTRY;
};

export type TransitExitPeriphery = TransitPeriphery & {
    type: CloudConnectorPeripheryType.TRANSIT_EXIT;
};

export type AccessPeriphery = CloudConnectorPeripheryShared & {
    type: CloudConnectorPeripheryType.ACCESS;
};

export type CloudConnectorPeriphery =
    | EntryPeriphery
    | ExitPeriphery
    | PayStationPeriphery
    | TransitEntryPeriphery
    | TransitExitPeriphery
    | AccessPeriphery;

export function isEntryPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is EntryPeriphery {
    return periphery.type === CloudConnectorPeripheryType.ENTRY;
}

export function isExitPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is ExitPeriphery {
    return periphery.type === CloudConnectorPeripheryType.EXIT;
}

export function isPayStationPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is PayStationPeriphery {
    return periphery.type === CloudConnectorPeripheryType.PAY_STATION;
}

export function isTransitEntryPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is TransitEntryPeriphery {
    return periphery.type === CloudConnectorPeripheryType.TRANSIT_ENTRY;
}

export function isTransitExitPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is TransitExitPeriphery {
    return periphery.type === CloudConnectorPeripheryType.TRANSIT_EXIT;
}

export function isAccessPeriphery(
    periphery: CloudConnectorPeriphery,
): periphery is AccessPeriphery {
    return periphery.type === CloudConnectorPeripheryType.ACCESS;
}
