function assembleDevUrl(appUrlTag: string) {
    const match = window.location.host.match(
        /operator\.(.*)\.digitalparking\.(dev|ch)/,
    );
    if (match && match.length === 3) {
        return `https://${appUrlTag}.${match[1]}.digitalparking.${match[2]}`;
    }
    return null;
}

export const parkingaboUrl = () => {
    const devUrl = assembleDevUrl('parkingabo');

    if (window.location.host === 'operator.digitalparking.ch') {
        return 'https://parkingabo.ch';
    }
    if (window.location.host === 'operator.test.digitalparking.ch') {
        return 'https://parkingabo.test.digitalparking.ch';
    }
    if (window.location.host === 'operator.demo.parkingportal.ch') {
        return 'https://demo.parkingabo.ch';
    }
    return devUrl;
};
