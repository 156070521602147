import * as superagent from 'superagent';
import {
    generateWriteStateSlice,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices.ts';
import { request } from '../AsyncRequest.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';

export type ResetPasswordResponseFailure =
    | ResponseNoEmailForUser
    | ResponseNoEmailForAdminUser;

export type ResetPasswordResponseSuccess = object;

interface ResponseNoEmailForUser {
    code: ResponseFailureCode.NO_EMAIL_FOR_USER;
    organizationAdminName: string;
}

interface ResponseNoEmailForAdminUser {
    code: ResponseFailureCode.NO_EMAIL_FOR_ADMIN_USER;
}

export enum ResponseFailureCode {
    NO_EMAIL_FOR_USER = 'NO_EMAIL_FOR_USER',
    NO_EMAIL_FOR_ADMIN_USER = 'NO_EMAIL_FOR_ADMIN_USER',
}

export namespace PostResetPasswordRemoteRequestState {
    export type State = ServerRequestState<
        ResetPasswordResponseSuccess,
        ResetPasswordResponseFailure
    >;

    export const postUsername = request(
        (data: { username: string; language: Language; source: 'WEB' }) =>
            superagent
                .post('/ui-api/operator-account/reset-password')
                .send(data),
        (store, res) => {
            setResponse(store, res);
            return 'reset-password';
        },
    );

    export const { get, setResponse } = generateWriteStateSlice<
        ResetPasswordResponseSuccess,
        ResetPasswordResponseFailure
    >({
        key: 'reset-password-form-controller-post-reset-password-remote-request',
    });
}
