import { isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Response } from '../../AsyncRequest.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import moment from 'moment';

export type FirmwareStatus = 'ready' | 'released';

export interface SimpleFirmwareImage {
    firmwareId: number;
    status: FirmwareStatus;
    versionMajor: number;
    versionMinor: number;
    versionRevision: number;
    releaseDate?: Maybe<moment.Moment>;
    isLatest: boolean;
}

interface SimpleFirmwareImageUnparsed
    extends Omit<SimpleFirmwareImage, 'releaseDate'> {
    releaseDate: string;
}

export function getById(
    images: SimpleFirmwareImage[],
    firmwareId?: Maybe<number>,
): Maybe<SimpleFirmwareImage> {
    return images.find(i => i.firmwareId === firmwareId);
}

export function parseSimpleFirmwareImage(
    i: SimpleFirmwareImageUnparsed,
): SimpleFirmwareImage {
    return Object.assign(i, {
        releaseDate: Date.Parser.isoToMoment(i.releaseDate),
    });
}

/**
 * Used in operator specific context to get most important information about available firmware images.
 * Only released images are loaded.
 */
export namespace List {
    export type State = ServerStateSlice.ServerState<SimpleFirmwareImage[]>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<SimpleFirmwareImage[]>(
            'FirmwareState-Firmwares',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchFirmwares);
                }
            },
            (body: SimpleFirmwareImageUnparsed[] | null) => {
                if (isUndefined(body)) {
                    return [];
                } else {
                    return body.map(parseSimpleFirmwareImage);
                }
            },
        );

    const fetchFirmwares = AsyncRequest.request(
        Http.OperatorAccount.Firmwares.get,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'FirmwareState-fetchFirmwares';
        },
    );
}
