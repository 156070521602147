import moment from 'moment';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import { LicensePlate } from '../components/AddLicensePlateSlideIn.tsx';
import { Badge } from '../components/AddBadgeSlideIn.tsx';

export interface Overlap {
    permitId: number;
    identifier: string;
    type: string;
    validFrom: moment.Moment;
    validTo: moment.Moment;
}

export interface OverlapUnparsed
    extends Omit<Overlap, 'validFrom' | 'validTo'> {
    validFrom: string;
    validTo: string;
}

export const parseOverlaps = (overlaps: OverlapUnparsed[]): Overlap[] => {
    return overlaps.map((o: OverlapUnparsed): Overlap => {
        return {
            permitId: o.permitId,
            identifier: o.identifier,
            type: o.type,
            validFrom: Parser.isoToMoment(o.validFrom),
            validTo: Parser.isoToMoment(o.validTo),
        };
    });
};

export const parseLpsThatNeedWhitelist = (
    lpsThatNeedWhitelist: LicensePlate[],
): LicensePlate[] => {
    return lpsThatNeedWhitelist.map((o: LicensePlate): LicensePlate => {
        return {
            id: o.id,
            licensePlateNr: o.licensePlateNr,
            type: o.type,
            country: o.country,
        };
    });
};

export const parseBadgesThatNeedWhitelist = (
    badgesThatNeedWhitelist: Badge[],
): Badge[] => {
    return badgesThatNeedWhitelist.map((o: Badge): Badge => {
        return {
            id: o.id,
            badgeLabelNr: o.badgeLabelNr,
        };
    });
};
