import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Localized } from '../../common/components/Localized';

export enum ConfirmationDialogVersions {
    SUCCESS = 'SUCCESS',
    SOFT_WARNING = 'SOFT_WARNING',
    HARD_WARNING = 'HARD_WARNING',
    ERROR = 'ERROR',
}

export function ConfirmationDialog({
    open,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
    confirmDisabled,
    title,
    children,
    dialogVersion,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel?: () => void;
    confirmLabel: JSX.Element;
    cancelLabel?: JSX.Element;
    confirmDisabled?: boolean;
    title: JSX.Element;
    children: JSX.Element;
    dialogVersion: ConfirmationDialogVersions;
}) {
    return (
        <Dialog open={open} onClose={onCancel ?? onConfirm} fullWidth={true}>
            <DialogTitle
                sx={theme => ({
                    display: 'flex',
                    alignItems: 'center',
                    background: backgroundColor(theme, dialogVersion),
                    color: titleColor(theme, dialogVersion),
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    padding: '12px 24px',
                })}
            >
                {title}
            </DialogTitle>
            <Box>
                <DialogContent
                    sx={{
                        padding: '8px 24px',
                    }}
                >
                    {children}
                </DialogContent>
            </Box>
            <DialogActions
                sx={{
                    padding: '0px 24px 20px 0px',
                }}
            >
                {onCancel && (
                    <Button onClick={onCancel}>
                        {cancelLabel ?? (
                            <Localized
                                de="Abbrechen"
                                fr="Annuler"
                                it="Annulla"
                                en="Cancel"
                            />
                        )}
                    </Button>
                )}
                <Button
                    onClick={onConfirm}
                    variant={'outlined'}
                    disabled={confirmDisabled}
                    color={
                        dialogVersion ===
                        ConfirmationDialogVersions.HARD_WARNING
                            ? 'error'
                            : 'primary'
                    }
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function backgroundColor(
    theme: Theme,
    version: ConfirmationDialogVersions,
): string {
    switch (version) {
        case ConfirmationDialogVersions.SUCCESS:
            return theme.palette.success.main;
        case ConfirmationDialogVersions.SOFT_WARNING:
        case ConfirmationDialogVersions.HARD_WARNING:
            return theme.palette.warning.main;
        case ConfirmationDialogVersions.ERROR:
            return theme.palette.error.main;
    }
}

function titleColor(theme: Theme, version: ConfirmationDialogVersions): string {
    switch (version) {
        case ConfirmationDialogVersions.SUCCESS:
            return theme.palette.success.contrastText;
        case ConfirmationDialogVersions.SOFT_WARNING:
        case ConfirmationDialogVersions.HARD_WARNING:
            return theme.palette.warning.contrastText;
        case ConfirmationDialogVersions.ERROR:
            return theme.palette.error.contrastText;
    }
}
