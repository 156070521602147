import { Message } from 'dg-web-shared/lib/Localized.ts';
import { Location, NavigateFunction, useLocation } from 'react-router-dom';

import { DateTime } from 'luxon';
import { BackendFilterType } from './OperatorFilterBackendConfiguration.tsx';
import { useMemo } from 'react';

export const OMNIBOX_SEARCH_PARAM_NAME = 'q';

export type FilterConfig = {
    [index: string]: FilterField;
};

export type FilterField =
    | FilterSelectionField
    | FilterDateField
    | FilterDateRangeField
    | FilterZoneField;

export interface FilterSelectionField {
    type: BackendFilterType.SELECTION;
    displayName: Message;
    values: FilterFieldOptions;
}

export interface FilterFieldOptions {
    [index: string]: {
        displayName: Message;
    };
}

export interface FilterDateField {
    type: BackendFilterType.DATE;
    displayName: Message;
    disableSelectionInFuture: boolean;
    value: DateTime | null;
}

export interface FilterDateRangeField {
    type: BackendFilterType.DATE_RANGE;
    displayName: Message;
    disableSelectionInFuture: boolean;
    fromValue: DateTime | null;
    toValue: DateTime | null;
}

export interface FilterZoneField {
    type: BackendFilterType.ZONE;
    displayName: Message;
    filters: FilterPlzField[];
}

export interface FilterPlzField {
    displayName: Message;
    values: FilterFieldOptions;
}

export function extractActiveFiltersFromQueryParams(
    params: URLSearchParams,
    filterConfig: FilterConfig,
): FilterConfig {
    const activeFilters: FilterConfig = {};
    const filterTypes = Object.keys(filterConfig).filter(key =>
        params.has(key),
    );
    filterTypes.forEach(filterType => {
        const filterField: FilterField = { ...filterConfig[filterType] };
        switch (filterField.type) {
            case BackendFilterType.SELECTION: {
                const filterValues: FilterFieldOptions = {};
                const activeFilterValues =
                    params.get(filterType)?.split(',') ?? [];

                activeFilterValues.forEach(value => {
                    const filter = filterConfig[filterType];
                    const valueExistsInConfig =
                        filter.type === BackendFilterType.SELECTION &&
                        Object.keys(filter.values).includes(value);
                    if (value !== '' && valueExistsInConfig) {
                        filterValues[value] = { ...filterField.values[value] };
                    }
                });

                filterField.values = filterValues;
                break;
            }
            case BackendFilterType.DATE:
                filterField.value = DateTime.fromISO(params.get(filterType)!);
                break;
            case BackendFilterType.DATE_RANGE: {
                const dates = params.get(filterType)?.split(',');

                filterField.fromValue =
                    dates && dates[0] ? DateTime.fromISO(dates[0]) : null;
                filterField.toValue =
                    dates && dates[1] ? DateTime.fromISO(dates[1]) : null;
                break;
            }
            case BackendFilterType.ZONE: {
                const activeZoneFilterValues =
                    params.get(filterType)?.split(',') ?? [];
                filterField.filters = filterField.filters.map(plzField => {
                    const zoneValues: FilterFieldOptions = {};
                    activeZoneFilterValues.forEach(value => {
                        if (value !== '' && plzField.values[value]) {
                            zoneValues[value] = { ...plzField.values[value] };
                        }
                    });
                    return {
                        displayName: plzField.displayName,
                        values: zoneValues,
                    };
                });
                break;
            }
        }
        activeFilters[filterType] = filterField;
    });
    return activeFilters;
}

function forEachActiveFilter(
    activeFilters: FilterConfig,
    action: (filterName: string, activeFilterField: FilterField) => void,
) {
    return Object.keys(activeFilters).forEach((filterName: string) => {
        action(filterName, activeFilters[filterName]);
    });
}

export function buildFilterPayload(
    activeFilters: FilterConfig,
    searchText: string | null,
): string {
    const newParams = new URLSearchParams();

    if (searchText) {
        newParams.set(OMNIBOX_SEARCH_PARAM_NAME, searchText);
    }

    forEachActiveFilter(activeFilters, (filterName, activeFilterField) => {
        switch (activeFilterField.type) {
            case BackendFilterType.DATE:
                if (activeFilterField.value?.toISODate()) {
                    newParams.set(
                        filterName,
                        activeFilterField.value.toISODate(),
                    );
                }
                break;
            case BackendFilterType.SELECTION: {
                const selectedValues = Object.keys(activeFilterField.values);
                if (selectedValues.length > 0) {
                    newParams.set(filterName, selectedValues.join(','));
                }
                break;
            }
            case BackendFilterType.DATE_RANGE:
                if (
                    activeFilterField.fromValue?.toISODate() &&
                    activeFilterField.toValue?.toISODate()
                ) {
                    newParams.set(
                        filterName,
                        [
                            activeFilterField.fromValue.toISODate(),
                            activeFilterField.toValue.toISODate(),
                        ].join(','),
                    );
                }
                break;
            case BackendFilterType.ZONE: {
                const zoneIds = extractZoneIds(activeFilterField);
                if (zoneIds.length > 0) {
                    newParams.set(filterName, zoneIds.join(','));
                }
                break;
            }
        }
    });

    const paramString = newParams.toString();
    return paramString.length > 0 ? `${paramString}` : '';
}

function extractZoneIds(activeFilterField: FilterZoneField) {
    return activeFilterField.filters.reduce((acc, filter) => {
        const zoneIds = Object.keys(filter.values);
        return [...acc, ...zoneIds];
    }, [] as string[]);
}

export function makeSearchQueryWithFilters(
    searchText: string | null,
    activeFilters: FilterConfig,
) {
    const newParams = new URLSearchParams();

    if (searchText) {
        newParams.set(OMNIBOX_SEARCH_PARAM_NAME, searchText);
    }

    forEachActiveFilter(activeFilters, (filterName, activeFilterField) => {
        switch (activeFilterField.type) {
            case BackendFilterType.DATE:
                newParams.set(
                    filterName,
                    activeFilterField.value?.toISODate()
                        ? activeFilterField.value.toISODate()
                        : '',
                );
                break;
            case BackendFilterType.SELECTION:
                newParams.set(
                    filterName,
                    Object.keys(activeFilterField.values).join(','),
                );
                break;
            case BackendFilterType.DATE_RANGE:
                newParams.set(
                    filterName,
                    [
                        activeFilterField.fromValue?.toISODate()
                            ? activeFilterField.fromValue.toISODate()
                            : '',
                        activeFilterField.toValue?.toISODate()
                            ? activeFilterField.toValue.toISODate()
                            : '',
                    ].join(','),
                );
                break;
            case BackendFilterType.ZONE:
                newParams.set(
                    filterName,
                    extractZoneIds(activeFilterField).join(','),
                );
                break;
        }
    });

    const paramString = newParams.toString();
    return paramString.length > 0 ? `?${paramString}` : '';
}

export function setSearchURL(
    navigate: NavigateFunction,
    location: Location,
    searchText: string | null,
    activeFilters: FilterConfig,
) {
    navigate(
        {
            pathname: location.pathname,
            search: makeSearchQueryWithFilters(searchText, activeFilters),
        },
        { replace: true },
    );
}

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}
