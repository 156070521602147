import moment from 'moment';

// used to track released of the parkingportal sync application

export const LATEST_GITHUB_RELEASE_API_URL =
    'https://api.github.com/repos/Digitalparking/parkingportal-sync/releases/latest';
export const LATEST_GITHUB_RELEASE_OVERVIEW_URL =
    'https://github.com/Digitalparking/parkingportal-sync/releases/latest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseRelease(r: any): GithubRelease {
    return {
        ...r,
        created_at: moment(r.created_at),
        published_at: moment(r.published_at),
        assets: r.assets.map(parseAsset),
        author: parseAuthor(r.author),
    } as GithubRelease;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseAsset(a: any): GithubReleaseAsset {
    return {
        ...a,
        created_at: moment(a.created_at),
        updated_at: moment(a.updated_at),
        uploader: parseAuthor(a.uploader),
    } as GithubReleaseAsset;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseAuthor(a: any): GithubAuthor {
    return {
        ...a,
    } as GithubAuthor;
}

export interface GithubRelease {
    url: string;
    html_url: string;
    assets_url: string;
    upload_url: string;
    tarball_url: string;
    zipball_url: string;
    id: number;
    tag_name: string;
    target_commitish: string;
    name: string;
    body: string;
    draft: boolean;
    prerelease: false;
    created_at: moment.Moment;
    published_at: moment.Moment;
    author: GithubAuthor;
    assets: GithubReleaseAsset[];
}

export interface GithubAuthor {
    login: string;
    id: number;
    avatar_url: string;
    gravatar_id: string;
    url: string;
    // ...
}

export interface GithubReleaseAsset {
    url: string;
    browser_download_url: string;
    id: number;
    name: string;
    label: string;
    state: string;
    content_type: string;
    size: number;
    download_count: number;
    created_at: moment.Moment;
    updated_at: moment.Moment;
    uploader: GithubAuthor;
}
