import { Localized } from '../common/components/Localized.tsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
} from '@mui/material';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { Control, Controller, Path, useForm } from 'react-hook-form';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField.tsx';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import Alert from '@mui/material/Alert';
import { SubmitButton } from '../ui/buttons/SubmitButton.tsx';
import {
    FilterConfig,
    makeSearchQueryWithFilters,
} from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { useOffstreetTransactionsFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { operatorAppApiUrl } from '../api/Http.ts';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { useEffect } from 'react';
import { useOffstreetTransactionTicketOutlet } from '../offstreet-transactions/OperatorOffstreetTransactionTicket.tsx';

const enum GuestPermitFormFields {
    contractTemplateId = 'contractTemplateId',
    reference = 'reference',
}

interface GuestPermitFormFieldValues extends GuestPermitValidToFieldValues {
    [GuestPermitFormFields.contractTemplateId]: number | null;
    [GuestPermitFormFields.reference]: string | null;
}

export const enum GuestPermitValidToFields {
    validToDate = 'validToDate',
    validToHourOfDay = 'validToHourOfDay',
}

export interface GuestPermitValidToFieldValues {
    [GuestPermitValidToFields.validToDate]: DateTime;
    [GuestPermitValidToFields.validToHourOfDay]: string;
}

interface GuestPermitOption {
    contractTemplateId: number;
    name: Message;
}

interface GuestPermitGenerateResponse {
    entryWithoutExitId: number;
    contractId: number;
}

export function CloudConnectorGenerateGuestPermit() {
    const navigate = useNavigate();
    const urlParams = useParams<{ ticketId: string }>();

    const { refetchList } = useOffstreetTransactionTicketOutlet();
    const { activeFilters, searchText } =
        useOffstreetTransactionsFilterConfiguration();
    const ticketId = urlParams.ticketId ? urlParams.ticketId : null;
    const [guestPermitOptionsState] = useServerFetch<
        GuestPermitOption[],
        {
            ticketId: string;
        },
        object
    >(
        ({ ticketId }) => ({
            url: `/ui-api/operator-account/cloud-connector/guest-permit/ticket/${ticketId}`,
        }),
        ticketId ? { ticketId: ticketId } : null,
    );

    const [generateGuestPermitState, generateGuestPermit] = useServerWrite<
        GuestPermitFormFieldValues & { ticketId: string | null },
        GuestPermitGenerateResponse,
        null
    >(() => ({
        url: `/ui-api/operator-account/cloud-connector/guest-permit/ticket/${ticketId}`,
    }));
    const generationPending =
        generateGuestPermitState.status === RequestStatus.PENDING;

    const { control, handleSubmit, formState, setValue, watch } =
        useForm<GuestPermitFormFieldValues>({
            defaultValues: {
                [GuestPermitFormFields.contractTemplateId]: null,
                [GuestPermitValidToFields.validToDate]: DateTime.now(),
                [GuestPermitValidToFields.validToHourOfDay]: '23:59',
                [GuestPermitFormFields.reference]: null,
            },
            mode: 'onBlur',
        });
    const validToDate = watch(GuestPermitValidToFields.validToDate);

    useServerSuccessEffect(guestPermitOptionsState, data => {
        if (data.length == 1) {
            setValue(
                GuestPermitFormFields.contractTemplateId,
                data[0].contractTemplateId,
                {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                },
            );
        }
    });

    useServerSuccessEffect(generateGuestPermitState, data => {
        refetchList();
        navigate(
            `${generatePath(
                `/offstreet-transactions/entry-without-exit/:entryWithoutExitId/detail/guest-permit-success/:contractId`,
                {
                    entryWithoutExitId: data.entryWithoutExitId.toString(),
                    contractId: data.contractId.toString(),
                },
            )}${makeSearchQueryWithFilters(searchText, activeFilters)}`,
            { replace: true },
        );
    });
    useServerErrorEffect(generateGuestPermitState, () =>
        navigate(
            `${generatePath(
                `error`,
            )}${makeSearchQueryWithFilters(searchText, activeFilters)}`,
        ),
    );
    const backUrl = getBackUrl(searchText, activeFilters);

    return (
        <OperatorRoutedModal
            variant={ModalVariant.TINY}
            backUrl={backUrl}
            render={() => (
                <>
                    <OperatorAsyncLoadedSection
                        requestState={guestPermitOptionsState}
                        render={guestPermits => (
                            <ParkingPortalLayoutWithHeader
                                title={
                                    <Localized
                                        de="Gastkarte generieren"
                                        fr="Générer carte d'hôte"
                                        it="Crea carta ospite"
                                        en="Generate guest card"
                                    />
                                }
                                backTo={backUrl}
                            >
                                <Alert variant="filled" severity="info">
                                    <Localized
                                        de="Die Gastkarte übernimmt die Einfahrtszeit des Tickets; das Ticket wird annulliert und kann vernichtet werden."
                                        fr="La carte d'hôte reprend l'heure d'entrée du ticket; le ticket est annulé et peut être détruit."
                                        it="La carta ospite riprende l'orario d'entrata del ticket; quest'ultimo viene annullato e può essere distrutto."
                                        en="The guest card reflects the time of entry on the ticket; the latter is cancelled and can be destroyed."
                                    />
                                </Alert>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit(
                                        (data: GuestPermitFormFieldValues) => {
                                            generateGuestPermit({
                                                ...data,
                                                ticketId: ticketId,
                                            });
                                        },
                                    )}
                                    sx={{
                                        padding: '0 24px 24px 24px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        flexGrow: 1,
                                    }}
                                >
                                    <ReactHookFormTextField
                                        label={
                                            <Localized
                                                de="Typ"
                                                fr="Type"
                                                it="Tipo"
                                                en="Type"
                                            />
                                        }
                                        name={
                                            GuestPermitFormFields.contractTemplateId
                                        }
                                        control={control}
                                        select
                                        rules={{ required: true }}
                                    >
                                        {guestPermits.map(guestPermit => (
                                            <MenuItem
                                                key={
                                                    guestPermit.contractTemplateId
                                                }
                                                value={
                                                    guestPermit.contractTemplateId
                                                }
                                            >
                                                <Localized
                                                    {...guestPermit.name}
                                                />
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormTextField>

                                    <GuestPermitValidToDateTimeDropdowns
                                        control={control}
                                        validToDate={validToDate}
                                        minDateTime={DateTime.now()}
                                    />

                                    <ReactHookFormTextField
                                        label={
                                            <Localized
                                                de="Referenz"
                                                fr="Référence"
                                                it="Referenza"
                                                en="Reference"
                                            />
                                        }
                                        name={GuestPermitFormFields.reference}
                                        control={control}
                                        select={false}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            onClick={() => navigate(backUrl)}
                                            variant="outlined"
                                            disabled={generationPending}
                                        >
                                            <Localized
                                                de="Abbrechen"
                                                fr="Annuler"
                                                it="Annulla"
                                                en="Cancel"
                                            />
                                        </Button>
                                        <SubmitButton
                                            sx={{ marginLeft: '5px' }}
                                            loading={generationPending}
                                            disabled={!formState.isValid}
                                            label={
                                                <Localized
                                                    de="Generieren"
                                                    fr="Générer"
                                                    it="Generare"
                                                    en="Generate"
                                                />
                                            }
                                        />
                                    </Box>
                                </Box>
                            </ParkingPortalLayoutWithHeader>
                        )}
                    />
                    <Outlet />
                </>
            )}
        />
    );
}

export function GuestPermitValidToDateTimeDropdowns<
    T extends GuestPermitValidToFieldValues,
>({
    control,
    validToDate,
    minDateTime,
}: {
    control: Control<T>;
    validToDate: DateTime;
    minDateTime: DateTime;
}) {
    const timeSelectionOptions = getTimeSelectionOptions(
        minDateTime,
        validToDate.hasSame(minDateTime, 'day'),
    );

    return (
        <Stack
            spacing={2}
            direction={'row'}
            sx={{
                display: 'flex',
                alignItems: 'baseline',
            }}
        >
            <Controller
                name={GuestPermitValidToFields.validToDate as Path<T>}
                control={control}
                render={({ field }) => (
                    <DesktopDatePicker
                        label={
                            <Localized
                                de="Gültig bis"
                                fr="Valide jusqu'à"
                                it="Valido fino a"
                                en="Valid Until"
                            />
                        }
                        value={field.value}
                        onChange={field.onChange}
                        minDate={minDateTime.plus({ minute: 1 })}
                        maxDate={DateTime.now().plus({
                            months: 1,
                        })}
                    />
                )}
                rules={{ required: true }}
            />

            <ReactHookFormTextField
                name={GuestPermitValidToFields.validToHourOfDay as Path<T>}
                control={control}
                select
                rules={{ required: true }}
                sx={{ width: '100px' }}
                slotProps={{
                    select: {
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                },
                            },
                        },
                    },
                }}
            >
                {timeSelectionOptions.map(hour => (
                    <MenuItem key={hour} value={hour}>
                        {hour}
                    </MenuItem>
                ))}
            </ReactHookFormTextField>
        </Stack>
    );
}

function getTimeSelectionOptions(time: DateTime, sameDay: boolean): string[] {
    const timeOptions = [
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '23:59',
    ];
    if (!sameDay) {
        return timeOptions;
    }
    return timeOptions.filter(o => o > time.toFormat('HH:mm'));
}

export function GuestPermitGenerationSuccessModal() {
    const navigate = useNavigate();
    const { activeFilters, searchText } =
        useOffstreetTransactionsFilterConfiguration();
    const operatorContext = useOperatorContext();
    const urlParams = useParams<{ contractId: string }>();
    const contractId = urlParams.contractId
        ? parseInt(urlParams.contractId, 10)
        : null;

    const downloadUrl = operatorAppApiUrl(
        `/${operatorContext.currentLogin.mandantId}/product/${contractId}/guest-permit-pdf`,
    );

    useEffect(() => {
        window.open(downloadUrl, '_blank', 'noreferrer');
    }, [downloadUrl]);

    return (
        <Dialog open={true} maxWidth="xs">
            <DialogTitle
                sx={{
                    backgroundColor: theme => theme.palette.success.main,
                    color: theme => theme.palette.white.main,
                }}
            >
                <Localized
                    de="Gastkarte erstellt"
                    fr="Carte d'hôte créée"
                    it="Carta ospite creata"
                    en="Guest card created"
                />
            </DialogTitle>
            <DialogContent sx={{ marginTop: '15px' }}>
                <p>
                    <Localized
                        de="Die Gastkarte wurde erfolgreich erstellt und das zugehörige Ticket wurde annulliert."
                        fr="La carte d'hôte a été créée avec succès et le ticket associé a été annulé."
                        it="La carta ospite è stata generata con successo e il relativo ticket è stato annullato."
                        en="The guest card was successfully created and the associated ticket was cancelled."
                    />
                </p>
                <Localized
                    de="Der Download wird automatisch gestartet. Sollte dies nicht funktionieren, können Sie ihn"
                    fr="Le téléchargement sera lancé automatiquement. Si cela ne fonctionne pas, vous pouvez"
                    it="Il download verrà avviato automaticamente. Se non dovesse funzionare potete"
                    en="The download will start automatically. If this does not happen, you can"
                />
                <Box sx={{ textDecoration: 'underline' }}>
                    <a
                        href={downloadUrl}
                        target="_blank"
                        download
                        rel="noreferrer"
                    >
                        <Localized
                            de=" hier manuell starten."
                            fr=" le lancer manuellement ici."
                            it=" avviarlo manualmente qui."
                            en=" start it manually here."
                        />
                    </a>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        navigate(getBackUrl(searchText, activeFilters))
                    }
                >
                    <Localized de="OK" fr="OK" it="OK" en="OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function GuestPermitGenerationErrorModal() {
    const navigate = useNavigate();
    const { activeFilters, searchText } =
        useOffstreetTransactionsFilterConfiguration();
    return (
        <Dialog open={true} maxWidth="xs">
            <DialogTitle
                sx={{
                    backgroundColor: theme => theme.palette.error.main,
                    color: theme => theme.palette.white.main,
                }}
            >
                <Localized
                    de="Gastkarte erstellen fehlgeschlagen"
                    fr="Échec de la création de la carte d'hôte"
                    it="Creazione della carta ospite non riuscita"
                    en="Guest card creation failed"
                />
            </DialogTitle>
            <DialogContent sx={{ marginTop: '15px' }}>
                <Localized
                    de="Ein unerwarteter Fehler ist aufgetreten."
                    fr="Une erreur inattendue s'est produite."
                    it="Si è verificato un errore imprevisto."
                    en="An unexpected error has occurred."
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        navigate(getBackUrl(searchText, activeFilters))
                    }
                >
                    <Localized de="OK" fr="OK" it="OK" en="OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function getBackUrl(
    searchText: string | null,
    activeFilters: FilterConfig,
): string {
    return `..${makeSearchQueryWithFilters(searchText, activeFilters)}`;
}
