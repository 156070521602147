import { useParams } from 'react-router-dom';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    ApprovingContractInfo,
    BarrierGateIdentificationEntity,
    OffstreetTransactionActions,
    OffstreetTransactionRefundBlockedExplanation,
    OffstreetTransactionRefundInputValues,
    OffstreetTransactionRefundPayload,
    OperatorRefund,
} from './OperatorOffstreetTransactionTypes.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { useEffect, useState } from 'react';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import {
    ApprovingContractInfoField,
    BarrierGateIdentificationEnityValue,
    getNetPayedPriceString,
    isInputAmountRefundable,
    OffstreetTransactionActionText,
    OffstreetTransactionOpen,
    OffstreetTransactionRefundBlocked,
    OffstreetTransactionRefundInputFields,
    OffstreetTransactionRefundResponseBanner,
    OperatorOffstreetTransactionDetailRoutedModal,
    parseChf,
} from './OperatorOffstreetTransactionShared.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { Box } from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { TerminalProvider } from '../common/state/ParkingsState.ts';
import { PaymentChannel } from '../common/models/PaymentChannel.ts';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { NicerPaymentChannel } from './OperatorOffstreetTransactionsRoute.tsx';
import {
    BlockLabel,
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';

export interface OffstreetTransactionContractDetails {
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    barrierGateProvider: TerminalProvider | null;
    refundBlockedExplanation: OffstreetTransactionRefundBlockedExplanation | null;
    zoneName: string;
    start: string;
    contractId: number;
    paymentChannel: PaymentChannel;
    end: string;
    createdAt: string;
    duration: string;
    priceChf: number;
    refund: OperatorRefund | null;
    approvingContractInfo: ApprovingContractInfo | null;
    bprofile: number | null;
}

export function OperatorOffstreetTransactionContract() {
    const urlParams = useParams<{ contractId: string }>();
    const contractId = urlParams.contractId
        ? parseInt(urlParams.contractId, 10)
        : null;
    const [offstreetTransactionsState, refetchOffstreetTransaction] =
        useServerFetch<
            OffstreetTransactionContractDetails,
            { contractId: number },
            null
        >(
            context => ({
                url: `/ui-api/operator-account/offstreet-transactions/contract/${context.contractId}`,
            }),
            contractId ? { contractId } : null,
        );

    return (
        <OperatorOffstreetTransactionDetailRoutedModal
            render={controller => (
                <OperatorAsyncLoadedSection
                    requestState={offstreetTransactionsState}
                    render={transaction => (
                        <TransactionTicketContractDetailContent
                            transaction={transaction}
                            onClose={controller.close}
                            refetchTransaction={refetchOffstreetTransaction}
                            onDirtyStateChange={
                                controller.setShouldConfirmBeforeLeave
                            }
                        />
                    )}
                />
            )}
        />
    );
}

function TransactionTicketContractDetailContent({
    transaction,
    onClose,
    refetchTransaction,
    onDirtyStateChange,
}: {
    transaction: OffstreetTransactionContractDetails;
    onClose: () => void;
    refetchTransaction: () => void;
    onDirtyStateChange: (shouldConfirm: boolean) => void;
}) {
    const { currentLogin } = useOperatorContext();
    const [openRefundResponseBanner, setOpenRefundResponseBanner] =
        useState(false);
    const [refundInputVisible, setRefundInputVisible] = useState(false);
    const [refundInputValues, setRefundInputValues] =
        useState<OffstreetTransactionRefundInputValues>({
            amount: '',
            remark: '',
        });
    const [refundTransactionState, refundTransaction] = useServerWrite<
        OffstreetTransactionRefundPayload,
        null
    >(() => ({
        url: `/ui-api/operator-account/park-transaction/refund`,
    }));

    function onRefundTransactionStateChange() {
        setOpenRefundResponseBanner(true);
        setRefundInputValues({
            amount: '',
            remark: '',
        });
        setRefundInputVisible(false);
    }

    useServerSuccessEffect(refundTransactionState, () => {
        refetchTransaction();
        onRefundTransactionStateChange();
    });

    useServerErrorEffect(
        refundTransactionState,
        onRefundTransactionStateChange,
    );

    useEffect(() => {
        onDirtyStateChange(refundInputVisible);
    }, [refundInputVisible]);

    const amountRappen = Math.round(parseChf(refundInputValues.amount) * 100);
    const isRefundable = isInputAmountRefundable(
        Math.round(transaction.priceChf * 100),
        amountRappen,
    );

    const refundAmountChf = transaction.refund
        ? transaction.refund.refundAmountRappen / 100
        : null;
    const netPayedPriceChf = getNetPayedPriceString(
        transaction.priceChf,
        refundAmountChf,
    );

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    title={
                        <Localized
                            de="Parkvorgang"
                            fr="Procédure"
                            it="Procedura"
                            en="Procedure"
                        />
                    }
                    editMode={refundInputVisible}
                    buttons={
                        !refundInputVisible ? (
                            <HeaderActionCloseButtons
                                onClose={onClose}
                                dropdownItems={[
                                    {
                                        key: 'reimburse',
                                        label: (
                                            <OffstreetTransactionActionText
                                                action={
                                                    OffstreetTransactionActions.REIMBURSE_CUSTOMER
                                                }
                                            />
                                        ),
                                        onClickCallback: () =>
                                            setRefundInputVisible(true),
                                        disabled:
                                            transaction.priceChf === 0 ||
                                            transaction.refund !== null ||
                                            !currentLogin.permissions
                                                .refundParkTransactions,
                                        tooltip:
                                            transaction.refundBlockedExplanation ? (
                                                <OffstreetTransactionRefundBlocked
                                                    refundBlockedExplanation={
                                                        transaction.refundBlockedExplanation
                                                    }
                                                />
                                            ) : undefined,
                                    },
                                    {
                                        key: 'download-receipt',
                                        label: (
                                            <OffstreetTransactionActionText
                                                action={
                                                    OffstreetTransactionActions.DOWNLOAD_RECEIPT
                                                }
                                            />
                                        ),
                                        onClickCallback: () => {
                                            window.open(
                                                `/ui-api/operator-account/park-transaction/${transaction.contractId}/receipt`,
                                                '_blank',
                                                'noopener,noreferrer',
                                            );
                                        },
                                        disabled: false,
                                    },
                                ]}
                            />
                        ) : (
                            <HeaderCancelSaveButtons
                                onCancel={() => {
                                    setRefundInputValues({
                                        amount: '',
                                        remark: '',
                                    });
                                    setRefundInputVisible(false);
                                }}
                                onSave={() => {
                                    if (isRefundable) {
                                        refundTransaction({
                                            contractId: transaction.contractId,
                                            amountRappen: amountRappen,
                                            remark: refundInputValues.remark,
                                        });
                                    }
                                }}
                                saveDisabled={!isRefundable}
                                loading={
                                    refundTransactionState.status ===
                                    RequestStatus.PENDING
                                }
                            />
                        )
                    }
                />
            }
            body={
                <FormContentLayout>
                    <FormLeftColumn>
                        <OffstreetTransactionOpen
                            isPresent={false}
                            hasAntipassback={false}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            }
                            value={
                                <BarrierGateIdentificationEnityValue
                                    barrierGateIdentificationEntity={
                                        transaction.barrierGateIdentificationEntity
                                    }
                                />
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Beginn"
                                    fr="Début"
                                    it="Inizio"
                                    en="Start"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.start),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Ende"
                                    fr="Fin"
                                    it="Fine"
                                    en="End"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.end),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Dauer"
                                    fr="Durée"
                                    it="Durata"
                                    en="Duration"
                                />
                            }
                            value={transaction.duration}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            }
                            value={transaction.zoneName}
                        />
                    </FormLeftColumn>
                    <FormRightColumn>
                        <OffstreetTransactionRefundResponseBanner
                            open={openRefundResponseBanner}
                            onClose={() => setOpenRefundResponseBanner(false)}
                            success={
                                refundTransactionState.status ===
                                RequestStatus.SUCCESS
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Preis"
                                    fr="Prix"
                                    it="Prezzo"
                                    en="Price"
                                />
                            }
                            value={netPayedPriceChf}
                        />
                        {refundInputVisible && (
                            <OffstreetTransactionRefundInputFields
                                price={transaction.priceChf}
                                refundInputValues={refundInputValues}
                                setRefundInputValues={setRefundInputValues}
                            />
                        )}
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Kanal"
                                    fr="Canal"
                                    it="Canale"
                                    en="Channel"
                                />
                            }
                            value={
                                <NicerPaymentChannel
                                    paymentChannel={transaction.paymentChannel}
                                />
                            }
                        />
                        {transaction.refund && (
                            <ReadOnlyTextField
                                label={
                                    <Localized
                                        de="Rückzahlung"
                                        fr="Remboursement"
                                        it="Rimborso"
                                        en="Refund"
                                    />
                                }
                                value={
                                    <>
                                        {`CHF ${(transaction.refund.refundAmountRappen / 100).toFixed(2)}`}
                                        <br />
                                        {Formatter.dayMonthYearHourMinute(
                                            DateTime.fromISO(
                                                transaction.refund.refundedAt,
                                            ),
                                        )}
                                        <br />
                                        {transaction.refund.remark}
                                        <br />
                                        {transaction.refund.refundedBy}
                                    </>
                                }
                            />
                        )}
                        <ApprovingContractInfoField
                            approvingContractInfo={
                                transaction.approvingContractInfo
                            }
                            bProfile={transaction.bprofile}
                        />
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                            }}
                        >
                            <BlockLabel
                                label={
                                    <Localized
                                        de="Erstellt am"
                                        fr="Créé le"
                                        it="Creato il"
                                        en="Created at"
                                    />
                                }
                            >
                                {Formatter.dayMonthYearHourMinute(
                                    DateTime.fromISO(transaction.createdAt),
                                )}
                            </BlockLabel>
                            <BlockLabel label="ID">
                                {transaction.contractId}
                            </BlockLabel>
                        </Box>
                    </FormRightColumn>
                </FormContentLayout>
            }
        />
    );
}
