import React from 'react';
import * as ReactDOM from 'react-dom';

interface ClickHandler {
    (e: Event): void;
}

interface OutsideClickableProps {
    children?: React.ReactNode;
    onClickOutside: ClickHandler | null;
}

export class OutsideClickable extends React.Component<OutsideClickableProps> {
    constructor(props: OutsideClickableProps) {
        super(props);
        this.documentClickhandler = this.documentClickhandler.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('click', this.documentClickhandler, true);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.documentClickhandler, true);
    }

    render(): JSX.Element | null {
        return this.props.children as JSX.Element;
    }

    private documentClickhandler(e: Event): void {
        // eslint-disable-next-line react/no-find-dom-node
        const self = ReactDOM.findDOMNode(this);
        if (
            (!self || !self.contains(e.target as Node)) &&
            this.props.onClickOutside
        ) {
            this.props.onClickOutside(e);
        }
    }
}
