import { ProductTemplateConfigType } from './ProductTemplate';

export const enum ProductTemplateUseCase {
    PERMIT_UNTIL_REVOCATION = 'PERMIT_UNTIL_REVOCATION',
    PERMIT_FIXED_DURATION = 'PERMIT_FIXED_DURATION',
    CALENDAR_DAY_RESERVATION = 'CALENDAR_DAY_RESERVATION',
    SUBSCRIPTION = 'SUBSCRIPTION',
    UBS_GUEST_RESERVATION = 'UBS_GUEST_RESERVATION',
    ALL_ACCESS_BARRIER_GATE = 'ALL_ACCESS_BARRIER_GATE',
    GUEST_PERMIT_VALIDTO_BARRIER_GATE = 'GUEST_PERMIT_VALIDTO_BARRIER_GATE',
}

export function deriveUseCasefromConfigType(
    configType: ProductTemplateConfigType,
): ProductTemplateUseCase {
    switch (configType) {
        case ProductTemplateConfigType.PERMIT_UNTIL_REVOCATION_BARRIER_GATE:
            return ProductTemplateUseCase.PERMIT_UNTIL_REVOCATION;
        case ProductTemplateConfigType.PERMIT_FREE_FIXED_DURATION_BARRIER_GATE:
            return ProductTemplateUseCase.PERMIT_FIXED_DURATION;
        case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_BARRIER_GATE:
        case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_ENFORCED:
            return ProductTemplateUseCase.CALENDAR_DAY_RESERVATION;
        case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE:
        case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED:
        case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED_APP:
        case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE_APP:
            return ProductTemplateUseCase.SUBSCRIPTION;
        case ProductTemplateConfigType.GUEST_RESERVATION_ENFORCED:
        case ProductTemplateConfigType.GUEST_RESERVATION_BARRIER_GATE:
            return ProductTemplateUseCase.UBS_GUEST_RESERVATION;
        case ProductTemplateConfigType.ALL_ACCESS_BARRIER_GATE:
            return ProductTemplateUseCase.ALL_ACCESS_BARRIER_GATE;
        case ProductTemplateConfigType.GUEST_PERMIT_VALIDTO_BARRIER_GATE:
            return ProductTemplateUseCase.GUEST_PERMIT_VALIDTO_BARRIER_GATE;
    }
}
export function useCaseNameFromConfigType(
    configType: ProductTemplateConfigType,
) {
    return nameOfProductTemplateUseCase(
        deriveUseCasefromConfigType(configType),
    );
}
export function nameOfProductTemplateUseCase(useCase: ProductTemplateUseCase) {
    switch (useCase) {
        case ProductTemplateUseCase.PERMIT_UNTIL_REVOCATION:
            return {
                de: 'Gratis-Bewilligung bis auf Widerruf',
                fr: "Autorisation gratuite jusqu'à révocation",
                it: 'Autorizzazione gratuita fino a revoca',
                en: 'Free permit until revocation',
            };
        case ProductTemplateUseCase.PERMIT_FIXED_DURATION:
            return {
                de: 'Gratis-Bewilligung mit fester Dauer',
                fr: 'Autorisation gratuite à durée fixe',
                it: 'Autorizzazione gratuita con durata fissa',
                en: 'Free permit with fixed duration',
            };
        case ProductTemplateUseCase.SUBSCRIPTION:
            return {
                de: 'Abonnement (noch nicht verfügbar)',
                fr: 'Abonnement (pas encore disponible)',
                it: 'Abbonamento (non ancora disponibile)',
                en: 'Subscription (not yet available)',
            };
        case ProductTemplateUseCase.CALENDAR_DAY_RESERVATION:
            return {
                de: 'Kalendertagsreservation (noch nicht verfügbar)',
                fr: 'Réservation journée de calendrier (pas encore disponible)',
                it: 'Prenotazione giorno di calendario (non ancora disponibile)',
                en: 'Calendar day reservation (not yet available)',
            };
        case ProductTemplateUseCase.UBS_GUEST_RESERVATION:
            return {
                de: 'Gäste Reservation',
                fr: 'Réservations clients',
                it: 'Prenotazioni degli ospiti',
                en: 'Guest reservations',
            };
        case ProductTemplateUseCase.ALL_ACCESS_BARRIER_GATE:
            return {
                de: 'Passe-Partout',
                fr: 'Passe-Partout',
                it: 'Passpartout',
                en: 'All Access',
            };
        case ProductTemplateUseCase.GUEST_PERMIT_VALIDTO_BARRIER_GATE:
            return {
                de: 'Gastkarte (noch nicht verfügbar)',
                fr: "Carte d'hôte (pas encore disponible)",
                it: 'Carta ospite (non ancora disponibile)',
                en: 'Guest card (not yet available)',
            };
    }
}
