import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import moment from 'moment';
import { Message } from 'dg-web-shared/lib/Localized.ts';

export namespace Layout {
    export interface State {
        filterOpen?: boolean;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'activity-statement-Layout',
        { filterOpen: false },
    );
}

export namespace Filter {
    export interface State {
        fromFilter: moment.Moment;
        toFilter: moment.Moment;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'activity-statement-filter',
        {
            fromFilter: moment()
                .startOf('month')
                .subtract(1, 'year')
                .startOf('year'),
            toFilter: moment().startOf('month'),
        },
    );
}

export namespace Data {
    export type AuditReportType = 'NONE' | 'PDF' | 'CSV';

    type BaseActivityMonth = {
        transactions: number;
        permits: number;
        parkingAboProducts: number;
        refundCount: number;
        comission: number;
        other: number;
        calculatedBalance: number;
        specialReport: AuditReportType;
        hasEndOfMonthReport: boolean;
        contractVersion: Message;
        recodeCount: number;
    };

    interface ActivityMonthInput extends BaseActivityMonth {
        period: string;
    }

    export interface ActivityMonth extends BaseActivityMonth {
        period: moment.Moment;
    }

    export interface ActivityMonth {
        period: moment.Moment;
        transactions: number;
        permits: number;
        parkingAboProducts: number;
        refundCount: number;
        comission: number;
        other: number;
        calculatedBalance: number;
        specialReport: AuditReportType;
        hasEndOfMonthReport: boolean;
        contractVersion: Message;
        recodeCount: number;
    }

    export type State = ServerStateSlice.ServerState<ActivityMonth[]>;

    export const { get, setResponse } = ServerStateSlice.generateServerState<
        ActivityMonth[]
    >(
        'activity-statement-Data',
        () => [],
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetch);
            }
        },
        (body: ActivityMonthInput[]): ActivityMonth[] => {
            return body.map((a: ActivityMonthInput): ActivityMonth => {
                return {
                    period: Parser.isoYearMonth(a.period),
                    transactions: a.transactions,
                    permits: a.permits,
                    parkingAboProducts: a.parkingAboProducts,
                    comission: a.comission,
                    refundCount: a.refundCount,
                    other: a.other,
                    calculatedBalance: a.calculatedBalance,
                    specialReport: a.specialReport,
                    hasEndOfMonthReport: a.hasEndOfMonthReport,
                    contractVersion: a.contractVersion,
                    recodeCount: a.recodeCount,
                };
            });
        },
    );
    const fetch = AsyncRequest.request(
        Http.OperatorAccount.ActivityStatement.get,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'activity-statement-fetch';
        },
    );
}
