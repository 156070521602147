import * as NumberFormatter from '../lib/NumberFormatter';
import { Translation } from '../lib/Text';
import { tokenNameTexts } from './i18n/CoinTexts';
import { getOrElse, isDefined, Maybe } from '../lib/MaybeV2';

const CURRENCY_MAP: { [key: string]: string } = {
    CH: 'CHF',
    EU: 'EUR',
    TK: 'CHF',
};

// The coin code according to the international coin specification.
// CHxxxx for Swiss coins, EUxxxx for Euro coins, TKxxxx for tokens.
// TK001A: Silver, diameter 28 mm.
// TK002A: Golden, diameter: 22 mm, thinner than TK003A.
// TK003A: Golden, diameter: 22 mm, thicker than TK002A.
export interface TokenNames {
    TK001A: Translation;
    TK002A: Translation;
    TK003A: Translation;
}

export function coinCurrency(coinCode: string, _language: string): string {
    return CURRENCY_MAP[coinCode.substring(0, 2)];
}

interface TokenValues {
    tk001aValue: Maybe<number>;
    tk002aValue: Maybe<number>;
    tk003aValue: Maybe<number>;
}

interface CoinNameOptions {
    withCurrency?: Maybe<boolean>;
    tokenValues?: Maybe<TokenValues>;
}

const DEFAULT_OPTIONS: CoinNameOptions = {
    withCurrency: false,
};

export function coinName(
    coinCode: string,
    language: string,
    options: CoinNameOptions = DEFAULT_OPTIONS,
): string {
    if (isToken(coinCode)) {
        // tokens have special names
        const tokenLabel = tokenNameTexts[language][coinCode]();
        if (isDefined(options.tokenValues)) {
            // also display value of coin if defined
            const coinValue =
                options.tokenValues[
                    (coinCode.toLowerCase() + 'Value') as keyof TokenValues
                ];
            if (isDefined(coinValue)) {
                const value = NumberFormatter.numberToLocalPrice(
                    language,
                    coinValue,
                    false,
                );
                return `${tokenLabel} (${coinCurrency(
                    'CH',
                    language,
                )} ${value})`;
            }
        }
        return tokenLabel;
    }
    const rappen = parseInt(coinCode.substring(2, coinCode.length - 1), 10);
    const value = NumberFormatter.numberToLocalPrice(
        language,
        rappen / 100,
        false,
    );
    if (
        options &&
        getOrElse(options.withCurrency, DEFAULT_OPTIONS.withCurrency)
    ) {
        return `${coinCurrency(coinCode, language)} ${value}`;
    } else {
        return value;
    }
}

export function coinValue(
    coinCode: string,
    language: string,
    options: CoinNameOptions = DEFAULT_OPTIONS,
): string {
    if (isToken(coinCode)) {
        if (isDefined(options.tokenValues)) {
            const coinValue =
                options.tokenValues[
                    (coinCode.toLowerCase() + 'Value') as keyof TokenValues
                ];
            if (isDefined(coinValue)) {
                const value = NumberFormatter.numberToLocalPrice(
                    language,
                    coinValue,
                    false,
                );
                return options.withCurrency
                    ? `${coinCurrency('CH', language)} ${value}`
                    : value;
            }
        }
        // unknown token value
        return '';
    } else {
        const rappen = parseInt(coinCode.substring(2, coinCode.length - 1), 10);
        const value = NumberFormatter.numberToLocalPrice(
            language,
            rappen / 100,
            false,
        );
        return getOrElse(options.withCurrency, DEFAULT_OPTIONS.withCurrency)
            ? `${coinCurrency(coinCode, language)} ${value}`
            : value;
    }
}

export function isToken(coinCode: string): coinCode is TokenCoinCode {
    return coinCode.substring(0, 2) === 'TK';
}

export function isEuro(coinCode: string): boolean {
    return coinCode.substring(0, 2) === 'EU';
}

export function isChf(coinCode: string): boolean {
    return coinCode.substring(0, 2) === 'CH';
}

type TokenCoinCode = 'TK001A' | 'TK002A' | 'TK003A';
