import { css } from '@emotion/css';
import { CSSInterpolation } from '@emotion/css/create-instance';

import * as ReactDOM from 'react-dom';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { IconButton24pxType } from '../buttons/IconButton.tsx';
import { Color } from '../Colors.ts';
import { UiContext } from '../Context.ts';
import {
    ActionButton,
    ActionButtons,
    ActionButtonsContainer,
    BelowContent,
    Content,
    Label,
    LabeledElement,
} from './Internal.tsx';
import React from 'react';

export enum TextFieldIcon {
    info,
}

interface Props {
    value: string;
    label: React.ReactNode;
    inputType: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (v: any) => void;
    saveable?: boolean;
    onBlur?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    disabled?: boolean;
    errorText?: React.ReactNode;
    tabIndex?: Maybe<number>;
    maxLength?: Maybe<number>;
    multiline?: Maybe<boolean>;
    context?: UiContext;
    autofocus?: boolean;
    min?: number;
    max?: number;
    step?: number;
    toolTip?: React.ReactNode;
    toolTipRight?: boolean;
}

interface State {
    focused?: boolean;
    hovered?: boolean;
}

export class TextField extends React.Component<Props, State> {
    declare refs: {
        input: HTMLElement;
        [key: string]: React.ReactInstance;
    };

    constructor(p: Props) {
        super(p);
        this.state = { focused: false, hovered: false };
    }

    focusInputField(): void {
        // eslint-disable-next-line react/no-string-refs
        if (this.refs.input) {
            // eslint-disable-next-line react/no-string-refs
            this.refs.input.focus();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _onChange(e: any): void {
        const val = e.target.value;
        this.props.onChange(val);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (!nextProps.saveable && this.state.hovered) {
            this.setState({ hovered: false });
        }
    }

    componentDidMount() {
        if (this.props.autofocus === true && !this.props.disabled) {
            this.focusInputField();
        }
    }

    renderSaveCancelIcons(): JSX.Element | null {
        if (this.props.saveable) {
            return (
                <ActionButtonsContainer>
                    <ActionButton
                        type={IconButton24pxType.apply}
                        onMouseOver={() => this.setState({ hovered: true })}
                        onMouseOut={() => this.setState({ hovered: false })}
                        onClick={this.props.onSave || (() => {})}
                    />
                    <ActionButton
                        type={IconButton24pxType.clear}
                        onMouseOver={() => this.setState({ hovered: true })}
                        onMouseOut={() => this.setState({ hovered: false })}
                        onClick={this.props.onCancel || (() => {})}
                    />
                </ActionButtonsContainer>
            );
        } else {
            return null;
        }
    }

    UNSAFE_componentWillUpdate(nextProps: Props): void {
        /* tslint:disable:no-string-literal */
        // eslint-disable-next-line react/no-find-dom-node, @typescript-eslint/no-explicit-any,react/no-string-refs
        const node: any = ReactDOM.findDOMNode(this.refs['input']);
        /*tslint:enable:no-string-literal */
        const oldLength = node.value.length; // this is the value of the field after the manual input
        const oldIdx = node.selectionStart;

        // return if there is no change, otherwise this will steal the focus (sort of) of other input fields
        // nextProps.value is the value we will ultimately display. It is the value
        // after the manual input *and* with automatic modifications made to that input (if any)
        if (
            node.value === nextProps.value ||
            nextProps.value === undefined ||
            nextProps.value === null
        ) {
            return;
        }

        node.value = nextProps.value;
        const newIdx = Math.max(0, node.value.length - oldLength + oldIdx);
        node.selectionStart = node.selectionEnd = newIdx;
    }

    hasValue(): boolean {
        return !!this.props.value;
    }

    renderHint(): React.ReactNode {
        if (this.hasValue()) {
            return null;
        } else {
            return (
                <div
                    className={css({
                        zIndex: -1,
                        width: '100%',
                        position: 'absolute',
                        ...Typo.heading1I,
                        color:
                            this.props.context === 'darkblue'
                                ? `rgba(${Color.white}, 0.6)`
                                : `rgba(${Color.darkblue}, 0.6)`,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    })}
                    data-context={this.props.context}
                >
                    {this.props.label}
                </div>
            );
        }
    }

    renderInput(): JSX.Element {
        const styles: CSSInterpolation = {
            margin: 0,
            padding: 0,
            border: 'none',
            outline: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'normal',
            background: 'none',
            lineHeight: 'inherit',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            color: 'inherit',
            fontWeight: 'inherit',
            display: 'block',
            width: '100%',
            '&:focus': {
                outline: 0,
            },
        };

        if (this.props.multiline) {
            return (
                <textarea
                    className={css(styles, { height: 96 })} // eslint-disable-next-line react/no-string-refs
                    ref="input"
                    value={this.props.value}
                    onFocus={(): void => this.setState({ focused: true })}
                    onBlur={(): void => {
                        this.setState({ focused: false });

                        if (this.props.onBlur) {
                            this.props.onBlur();
                        }
                    }}
                    onChange={(
                        e: React.SyntheticEvent<HTMLTextAreaElement>,
                    ): void => this._onChange(e)}
                    disabled={this.props.disabled}
                    tabIndex={this.props.tabIndex || undefined}
                    maxLength={this.props.maxLength || undefined}
                />
            );
        } else {
            return (
                <input
                    className={css(styles)} // eslint-disable-next-line react/no-string-refs
                    ref="input"
                    value={this.props.value}
                    type={this.props.inputType}
                    onFocus={(): void => this.setState({ focused: true })}
                    onBlur={(): void => {
                        this.setState({ focused: false });

                        if (this.props.onBlur) {
                            this.props.onBlur();
                        }
                    }}
                    onChange={(
                        e: React.SyntheticEvent<HTMLInputElement>,
                    ): void => this._onChange(e)}
                    disabled={this.props.disabled}
                    tabIndex={this.props.tabIndex || undefined}
                    maxLength={this.props.maxLength || undefined}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                />
            );
        }
    }

    render() {
        return (
            <LabeledElement
                actionButtons={
                    this.props.saveable ? ActionButtons.two : ActionButtons.none
                }
                onMouseOver={() => this.setState({ hovered: true })}
                onMouseOut={() => this.setState({ hovered: false })}
            >
                <Label
                    focused={this.state.focused || false}
                    hovered={false}
                    label={this.props.label}
                    context={this.props.context}
                    toolTip={this.props.toolTip}
                    toolTipRight={this.props.toolTipRight}
                />
                <Content
                    hovered={false}
                    userSelectable={true}
                    empty={false}
                    focused={this.state.focused || false}
                    icon={null}
                    context={this.props.context}
                >
                    {this.renderInput()}
                    {this.renderSaveCancelIcons()}
                </Content>
                <BelowContent
                    focused={this.state.focused || false}
                    hovered={false}
                    underline={true}
                    errorText={this.props.errorText}
                    context={this.props.context}
                />
            </LabeledElement>
        );
    }
}
