import { Message } from 'dg-web-shared/lib/Localized.ts';
import {
    Navigate,
    Outlet,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { RecodeConditionType } from 'dg-web-shared/model/RecodeCondition.ts';
import { useOffstreetTransactionTicketOutlet } from '../offstreet-transactions/OperatorOffstreetTransactionTicket.tsx';

export type TicketRecodeCondition =
    | FreeTicketRecodeCondition
    | TariffTicketRecodeCondition;

export interface TicketRecodeConditionBase {
    recodeConditionId: string;
    recodeConditionDescription: Message;
    zoneDescription: string;
    ticketApiString: string;
    ticketId: string;
    entryDateTime: string;
    type: RecodeConditionType;
}

export interface FreeTicketRecodeCondition extends TicketRecodeConditionBase {
    type: RecodeConditionType.FREE;
}

export interface TariffTicketRecodeCondition extends TicketRecodeConditionBase {
    tariffName: string;
    type: RecodeConditionType.TARIFF;
}

export enum RecodeErrorType {
    PARSING_FAILED = 'PARSING_FAILED',
    IDENTIFICATION_UNKNOWN = 'IDENTIFICATION_UNKNOWN',
    ZONE_NOT_AVAILABLE = 'ZONE_NOT_AVAILABLE',
    ALREADY_PAID = 'ALREADY_PAID',
    ONE_TIME_EXIT_SCANNED = 'ONE_TIME_EXIT_SCANNED',
}

export function CloudConnectorRecodeTicketScanConditionsOutlet() {
    const { ticketScan } = useParams<{ ticketScan: string }>();
    const [applicableRecodeConditionListState] = useServerFetch<
        TicketRecodeCondition[],
        {
            ticketScan: string;
        },
        RecodeErrorType
    >(
        ({ ticketScan }) => ({
            url: `/ui-api/operator-account/cloud-connector/recode/ticketScan/${ticketScan}`,
        }),
        ticketScan ? { ticketScan: ticketScan } : null,
    );
    return (
        <OperatorRoutedModal
            variant={ModalVariant.MOBILE}
            backUrl={'..'}
            render={() => (
                <OperatorAsyncLoadedSection
                    requestState={applicableRecodeConditionListState}
                    render={recodeConditions => (
                        <RecodeTicketOutlet
                            context={{ recodeConditions: recodeConditions }}
                        />
                    )}
                    renderError={recodeError => (
                        <Navigate to={`../error/${recodeError}`} />
                    )}
                />
            )}
        />
    );
}

export function CloudConnectorRecodeTicketByIdConditionsOutlet() {
    const urlParams = useParams<{ ticketId: string }>();
    const ticketId = urlParams.ticketId ? urlParams.ticketId : null;
    const { refetchTicketDetailDesktop } =
        useOffstreetTransactionTicketOutlet();
    const [applicableRecodeConditionListState] = useServerFetch<
        TicketRecodeCondition[],
        {
            ticketId: string;
        },
        RecodeErrorType
    >(
        ({ ticketId }) => ({
            url: `/ui-api/operator-account/cloud-connector/recode/ticketById/${ticketId}`,
        }),
        ticketId ? { ticketId: ticketId } : null,
    );
    return (
        <OperatorRoutedModal
            variant={ModalVariant.SMALL}
            backUrl={'..'}
            render={() => (
                <OperatorAsyncLoadedSection
                    requestState={applicableRecodeConditionListState}
                    render={recodeConditions => (
                        <RecodeTicketOutlet
                            context={{
                                recodeConditions: recodeConditions,
                                refetchTicketDetailDesktop:
                                    refetchTicketDetailDesktop,
                            }}
                        />
                    )}
                    renderError={recodeError => (
                        <Navigate to={`error/${recodeError}`} />
                    )}
                />
            )}
        />
    );
}

export function useRecodeTicketOutlet() {
    return useOutletContext<RecodeTicketOutletContext>();
}

function RecodeTicketOutlet({
    context,
}: {
    context: RecodeTicketOutletContext;
}) {
    return <Outlet context={context} />;
}

interface RecodeTicketOutletContext {
    recodeConditions: TicketRecodeCondition[];
    refetchTicketDetailDesktop?: () => void;
}
