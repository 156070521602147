import {
    generatePath,
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    BarrierGateIdentificationTicket,
    OffstreetTransactionActions,
    OffstreetTransactionRefundBlockedExplanation,
    OffstreetTransactionRefundInputValues,
    OffstreetTransactionRefundPayload,
    OperatorRefund,
} from './OperatorOffstreetTransactionTypes.ts';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import {
    BarrierGateIdentificationEnityValue,
    getNetPayedPriceString,
    isInputAmountRefundable,
    NoReceiptForRunningContract,
    OffstreetTransactionActionText,
    OffstreetTransactionOpen,
    OffstreetTransactionRefundBlocked,
    OffstreetTransactionRefundInputFields,
    OffstreetTransactionRefundResponseBanner,
    OperatorOffstreetTransactionDetailRoutedModal,
    parseChf,
} from './OperatorOffstreetTransactionShared.tsx';
import { useEffect, useState } from 'react';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { Box, Stack } from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { ErrorRounded, PendingRounded } from '@mui/icons-material';
import { PaymentChannel } from '../common/models/PaymentChannel.ts';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import {
    NicerPaymentChannel,
    TicketState,
    useOffstreetTransactionsOutlet,
} from './OperatorOffstreetTransactionsRoute.tsx';
import {
    BlockLabel,
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';
import { RecodeErrorType } from '../cloud-connector/CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { CloudConnectorRecodeErrorText } from '../cloud-connector/CloudConnectorRecodeResponse.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { useOffstreetTransactionsFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';

enum TicketOffstreetTransactionType {
    TICKET = 'TICKET',
    TICKET_CONTRACT = 'TICKET_CONTRACT',
}

interface TicketOffstreetTransactionDetailBase {
    zoneName: string;
    start: string;
    barrierGateIdentificationEntity: BarrierGateIdentificationTicket;
    recodeConditionDescription: Message | null;
}

interface TicketOffstreetTransactionDetails
    extends TicketOffstreetTransactionDetailBase {
    ticketOffstreetTransactionType: TicketOffstreetTransactionType.TICKET;
    refundBlockedExplanation: OffstreetTransactionRefundBlockedExplanation;
    ticketState: TicketState;
    ticketRecodeError: RecodeErrorType | null;
    showGuestPermitGeneration: boolean;
}

interface TicketContractOffstreetTransactionDetails
    extends TicketOffstreetTransactionDetailBase {
    ticketOffstreetTransactionType: TicketOffstreetTransactionType.TICKET_CONTRACT;
    refundBlockedExplanation: OffstreetTransactionRefundBlockedExplanation | null;
    contractId: number;
    paymentChannel: PaymentChannel;
    end: string;
    createdAt: string;
    duration: string;
    priceChf: number;
    refund: OperatorRefund | null;
    recodeDiscount: string;
    showGuestPermitGeneration: boolean;
}

type TicketOffstreetTransactionDetailTypes =
    | TicketOffstreetTransactionDetails
    | TicketContractOffstreetTransactionDetails;

export function OperatorOffstreetTransactionTicket() {
    const urlParams = useParams<{ ticketId: string }>();
    const { refetchList } = useOffstreetTransactionsOutlet();
    const ticketId = urlParams.ticketId ? urlParams.ticketId : null;
    const [offstreetTransactionsState, refetchOffstreetTransaction] =
        useServerFetch<
            TicketOffstreetTransactionDetailTypes,
            { ticketId: string },
            null
        >(
            context => ({
                url: `/ui-api/operator-account/offstreet-transactions/ticket/${context.ticketId}`,
            }),
            ticketId ? { ticketId } : null,
        );

    return (
        <>
            <OperatorOffstreetTransactionDetailRoutedModal
                render={controller => (
                    <OperatorAsyncLoadedSection
                        requestState={offstreetTransactionsState}
                        render={transaction => {
                            switch (
                                transaction.ticketOffstreetTransactionType
                            ) {
                                case TicketOffstreetTransactionType.TICKET:
                                    return (
                                        <RunningTicketDetailContent
                                            transaction={transaction}
                                            onClose={controller.close}
                                        />
                                    );
                                case TicketOffstreetTransactionType.TICKET_CONTRACT:
                                    return (
                                        <TerminatedTicketDetailContent
                                            transaction={transaction}
                                            onClose={controller.close}
                                            refetchTransaction={
                                                refetchOffstreetTransaction
                                            }
                                            onDirtyStateChange={
                                                controller.setShouldConfirmBeforeLeave
                                            }
                                        />
                                    );
                            }
                        }}
                    />
                )}
            />

            <OffstreetTransactionTicketOutlet
                context={{
                    refetchTicketDetailDesktop: refetchOffstreetTransaction,
                    refetchList: refetchList,
                }}
            />
        </>
    );
}

export function useOffstreetTransactionTicketOutlet() {
    return useOutletContext<OffstreetTransactionTicketOutletContext>();
}

function OffstreetTransactionTicketOutlet({
    context,
}: {
    context: OffstreetTransactionTicketOutletContext;
}) {
    return <Outlet context={context} />;
}

interface OffstreetTransactionTicketOutletContext {
    refetchTicketDetailDesktop: () => void;
    refetchList: () => void;
}

function RunningTicketDetailContent({
    transaction,
    onClose,
}: {
    transaction: TicketOffstreetTransactionDetails;
    onClose: () => void;
}) {
    const navigate = useNavigate();
    const {
        currentLogin: { permissions },
    } = useOperatorContext();
    const { activeFilters, searchText } =
        useOffstreetTransactionsFilterConfiguration();
    const recodeDisabled =
        !permissions.cloudConnectorRecodeTicket ||
        transaction.ticketRecodeError != null;

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    title={
                        <Localized
                            de="Ticket"
                            fr="Ticket"
                            it="Ticket"
                            en="Ticket"
                        />
                    }
                    editMode={false}
                    buttons={
                        <HeaderActionCloseButtons
                            onClose={onClose}
                            dropdownItems={[
                                ...(transaction.showGuestPermitGeneration
                                    ? [
                                          {
                                              key: 'generate-guest-permit',
                                              label: (
                                                  <OffstreetTransactionActionText
                                                      action={
                                                          OffstreetTransactionActions.GENERATE_GUEST_PERMIT
                                                      }
                                                  />
                                              ),
                                              onClickCallback: () =>
                                                  navigate(
                                                      `${generatePath(
                                                          `guest-permit`,
                                                      )}${makeSearchQueryWithFilters(searchText, activeFilters)}`,
                                                  ),
                                              disabled: false,
                                          },
                                      ]
                                    : []),
                                {
                                    key: 'recode',
                                    label: (
                                        <OffstreetTransactionActionText
                                            action={
                                                OffstreetTransactionActions.RECODE
                                            }
                                        />
                                    ),
                                    onClickCallback: () => navigate('recode'),
                                    disabled: recodeDisabled,
                                    tooltip: recodeDisabled ? (
                                        <RunningTicketTooltip
                                            recodePermission={
                                                permissions.cloudConnectorRecodeTicket
                                            }
                                            recodeError={
                                                transaction.ticketRecodeError
                                            }
                                        />
                                    ) : undefined,
                                },
                                {
                                    key: 'reimburse',
                                    label: (
                                        <OffstreetTransactionActionText
                                            action={
                                                OffstreetTransactionActions.REIMBURSE_CUSTOMER
                                            }
                                        />
                                    ),
                                    onClickCallback: () => {},
                                    disabled: true,
                                    tooltip: (
                                        <OffstreetTransactionRefundBlocked
                                            refundBlockedExplanation={
                                                transaction.refundBlockedExplanation
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: 'download-receipt',
                                    label: (
                                        <OffstreetTransactionActionText
                                            action={
                                                OffstreetTransactionActions.DOWNLOAD_RECEIPT
                                            }
                                        />
                                    ),
                                    onClickCallback: () => {},
                                    disabled: true,
                                    tooltip: <NoReceiptForRunningContract />,
                                },
                            ]}
                        />
                    }
                />
            }
            body={
                <FormContentLayout>
                    <FormLeftColumn>
                        <OffstreetTransactionOpen
                            isPresent={true}
                            hasAntipassback={false}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            }
                            value={
                                <BarrierGateIdentificationEnityValue
                                    barrierGateIdentificationEntity={
                                        transaction.barrierGateIdentificationEntity
                                    }
                                />
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Beginn"
                                    fr="Début"
                                    it="Inizio"
                                    en="Start"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.start),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Ende"
                                    fr="Fin"
                                    it="Fine"
                                    en="End"
                                />
                            }
                            value={EmDash}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Dauer"
                                    fr="Durée"
                                    it="Durata"
                                    en="Duration"
                                />
                            }
                            value={EmDash}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            }
                            value={transaction.zoneName}
                        />
                    </FormLeftColumn>
                    <FormRightColumn>
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Preis"
                                    fr="Prix"
                                    it="Prezzo"
                                    en="Price"
                                />
                            }
                            value={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PendingTicketPriceDisplay
                                        state={transaction.ticketState}
                                    />
                                </Box>
                            }
                        />
                        {transaction.recodeConditionDescription && (
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                    <ReadOnlyTextField
                                        label={
                                            <Localized
                                                de="Rabatt"
                                                fr="Rabais"
                                                it="Sconto"
                                                en="Rebate"
                                            />
                                        }
                                        value={EmDash}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                    <ReadOnlyTextField
                                        label={
                                            <Localized
                                                de="Bedingung"
                                                fr="Condition"
                                                it="Condizione"
                                                en="Condition"
                                            />
                                        }
                                        value={
                                            <Localized
                                                {...transaction.recodeConditionDescription}
                                            />
                                        }
                                    />
                                </Box>
                            </Stack>
                        )}
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Kanal"
                                    fr="Canal"
                                    it="Canale"
                                    en="Channel"
                                />
                            }
                            value={EmDash}
                        />
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                            }}
                        >
                            <BlockLabel
                                label={
                                    <Localized
                                        de="Erstellt am"
                                        fr="Créé le"
                                        it="Creato il"
                                        en="Created at"
                                    />
                                }
                            >
                                {EmDash}
                            </BlockLabel>
                            <BlockLabel label="ID">{EmDash}</BlockLabel>
                        </Box>
                    </FormRightColumn>
                </FormContentLayout>
            }
        />
    );
}

function TerminatedTicketDetailContent({
    transaction,
    onClose,
    refetchTransaction,
    onDirtyStateChange,
}: {
    transaction: TicketContractOffstreetTransactionDetails;
    onClose: () => void;
    refetchTransaction: () => void;
    onDirtyStateChange: (shouldConfirm: boolean) => void;
}) {
    const { currentLogin } = useOperatorContext();
    const [openRefundResponseBanner, setOpenRefundResponseBanner] =
        useState(false);
    const [refundInputVisible, setRefundInputVisible] = useState(false);
    const [refundInputValues, setRefundInputValues] =
        useState<OffstreetTransactionRefundInputValues>({
            amount: '',
            remark: '',
        });
    const [refundTransactionState, refundTransaction] = useServerWrite<
        OffstreetTransactionRefundPayload,
        null
    >(() => ({
        url: `/ui-api/operator-account/park-transaction/refund`,
    }));

    function onRefundTransactionStateChange() {
        setOpenRefundResponseBanner(true);
        setRefundInputValues({
            amount: '',
            remark: '',
        });
        setRefundInputVisible(false);
    }

    useServerSuccessEffect(refundTransactionState, () => {
        refetchTransaction();
        onRefundTransactionStateChange();
    });

    useServerErrorEffect(
        refundTransactionState,
        onRefundTransactionStateChange,
    );

    useEffect(() => {
        onDirtyStateChange(refundInputVisible);
    }, [refundInputVisible]);

    const amountRappen = Math.round(parseChf(refundInputValues.amount) * 100);
    const isRefundable = isInputAmountRefundable(
        Math.round(transaction.priceChf * 100),
        amountRappen,
    );

    const refundAmountChf = transaction.refund
        ? transaction.refund.refundAmountRappen / 100
        : null;
    const netPayedPriceChf = getNetPayedPriceString(
        transaction.priceChf,
        refundAmountChf,
    );

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    title={
                        <Localized
                            de="Ticket"
                            fr="Ticket"
                            it="Ticket"
                            en="Ticket"
                        />
                    }
                    editMode={refundInputVisible}
                    buttons={
                        !refundInputVisible ? (
                            <HeaderActionCloseButtons
                                onClose={onClose}
                                dropdownItems={[
                                    ...(transaction.showGuestPermitGeneration
                                        ? [
                                              {
                                                  key: 'generate-guest-permit',
                                                  label: (
                                                      <OffstreetTransactionActionText
                                                          action={
                                                              OffstreetTransactionActions.GENERATE_GUEST_PERMIT
                                                          }
                                                      />
                                                  ),
                                                  onClickCallback: () => {},
                                                  disabled: true,
                                                  tooltip: (
                                                      <Localized
                                                          de="Eine Gastkarte kann nur für laufende Parkvorgänge generiert werden"
                                                          fr="Une carte d'hôte ne peut être générée que pour les transactions de stationnement en cours"
                                                          it="Una carta ospite può essere generata solo per transazioni di parcheggio in corso"
                                                          en="A guest card can only be generated for ongoing parking transactions"
                                                      />
                                                  ),
                                              },
                                          ]
                                        : []),
                                    {
                                        key: 'recode',
                                        label: (
                                            <OffstreetTransactionActionText
                                                action={
                                                    OffstreetTransactionActions.RECODE
                                                }
                                            />
                                        ),
                                        onClickCallback: () => {},
                                        disabled: true,
                                        tooltip: (
                                            <RecodeBlockedForCompleteTransactions />
                                        ),
                                    },
                                    {
                                        key: 'reimburse',
                                        label: (
                                            <OffstreetTransactionActionText
                                                action={
                                                    OffstreetTransactionActions.REIMBURSE_CUSTOMER
                                                }
                                            />
                                        ),
                                        onClickCallback: () =>
                                            setRefundInputVisible(true),
                                        disabled:
                                            transaction.priceChf === 0 ||
                                            transaction.refund !== null ||
                                            !currentLogin.permissions
                                                .refundParkTransactions,
                                        tooltip:
                                            transaction.refundBlockedExplanation ? (
                                                <OffstreetTransactionRefundBlocked
                                                    refundBlockedExplanation={
                                                        transaction.refundBlockedExplanation
                                                    }
                                                />
                                            ) : undefined,
                                    },
                                    {
                                        key: 'download-receipt',
                                        label: (
                                            <OffstreetTransactionActionText
                                                action={
                                                    OffstreetTransactionActions.DOWNLOAD_RECEIPT
                                                }
                                            />
                                        ),
                                        onClickCallback: () => {
                                            window.open(
                                                `/ui-api/operator-account/park-transaction/${transaction.contractId}/receipt`,
                                                '_blank',
                                                'noopener,noreferrer',
                                            );
                                        },
                                        disabled: false,
                                    },
                                ]}
                            />
                        ) : (
                            <HeaderCancelSaveButtons
                                onCancel={() => {
                                    setRefundInputValues({
                                        amount: '',
                                        remark: '',
                                    });
                                    setRefundInputVisible(false);
                                }}
                                onSave={() => {
                                    if (isRefundable) {
                                        refundTransaction({
                                            contractId: transaction.contractId,
                                            amountRappen: amountRappen,
                                            remark: refundInputValues.remark,
                                        });
                                    }
                                }}
                                saveDisabled={!isRefundable}
                                loading={
                                    refundTransactionState.status ===
                                    RequestStatus.PENDING
                                }
                            />
                        )
                    }
                />
            }
            body={
                <FormContentLayout>
                    <FormLeftColumn>
                        <OffstreetTransactionOpen
                            isPresent={false}
                            hasAntipassback={false}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            }
                            value={
                                <BarrierGateIdentificationEnityValue
                                    barrierGateIdentificationEntity={
                                        transaction.barrierGateIdentificationEntity
                                    }
                                />
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Beginn"
                                    fr="Début"
                                    it="Inizio"
                                    en="Start"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.start),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Ende"
                                    fr="Fin"
                                    it="Fine"
                                    en="End"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.end),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Dauer"
                                    fr="Durée"
                                    it="Durata"
                                    en="Duration"
                                />
                            }
                            value={transaction.duration}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            }
                            value={transaction.zoneName}
                        />
                    </FormLeftColumn>
                    <FormRightColumn>
                        <OffstreetTransactionRefundResponseBanner
                            open={openRefundResponseBanner}
                            onClose={() => setOpenRefundResponseBanner(false)}
                            success={
                                refundTransactionState.status ===
                                RequestStatus.SUCCESS
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Preis"
                                    fr="Prix"
                                    it="Prezzo"
                                    en="Price"
                                />
                            }
                            value={netPayedPriceChf}
                        />
                        {refundInputVisible && (
                            <OffstreetTransactionRefundInputFields
                                price={transaction.priceChf}
                                refundInputValues={refundInputValues}
                                setRefundInputValues={setRefundInputValues}
                            />
                        )}
                        {transaction.recodeConditionDescription && (
                            <Stack
                                direction={'row'}
                                sx={{
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                    <ReadOnlyTextField
                                        label={
                                            <Localized
                                                de="Rabatt"
                                                fr="Rabais"
                                                it="Sconto"
                                                en="Rebate"
                                            />
                                        }
                                        value={transaction.recodeDiscount}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                    <ReadOnlyTextField
                                        label={
                                            <Localized
                                                de="Bedingung"
                                                fr="Condition"
                                                it="Condizione"
                                                en="Condition"
                                            />
                                        }
                                        value={
                                            <Localized
                                                {...transaction.recodeConditionDescription}
                                            />
                                        }
                                    />
                                </Box>
                            </Stack>
                        )}
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Kanal"
                                    fr="Canal"
                                    it="Canale"
                                    en="Channel"
                                />
                            }
                            value={
                                <NicerPaymentChannel
                                    paymentChannel={transaction.paymentChannel}
                                />
                            }
                        />
                        {transaction.refund && (
                            <ReadOnlyTextField
                                label={
                                    <Localized
                                        de="Rückzahlung"
                                        fr="Remboursement"
                                        it="Rimborso"
                                        en="Refund"
                                    />
                                }
                                value={
                                    <>
                                        {`CHF ${(transaction.refund.refundAmountRappen / 100).toFixed(2)}`}
                                        <br />
                                        {Formatter.dayMonthYearHourMinute(
                                            DateTime.fromISO(
                                                transaction.refund.refundedAt,
                                            ),
                                        )}
                                        <br />
                                        {transaction.refund.remark}
                                        <br />
                                        {transaction.refund.refundedBy}
                                    </>
                                }
                            />
                        )}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                            }}
                        >
                            <BlockLabel
                                label={
                                    <Localized
                                        de="Erstellt am"
                                        fr="Créé le"
                                        it="Creato il"
                                        en="Created at"
                                    />
                                }
                            >
                                {Formatter.dayMonthYearHourMinute(
                                    DateTime.fromISO(transaction.createdAt),
                                )}
                            </BlockLabel>
                            <BlockLabel label="ID">
                                {transaction.contractId}
                            </BlockLabel>
                        </Box>
                    </FormRightColumn>
                </FormContentLayout>
            }
        />
    );
}

export function TranslationForTicketState({ state }: { state: TicketState }) {
    switch (state) {
        case TicketState.BOOKED:
            return <Localized de="Bezahlt" fr="Payé" it="Pagato" en="Paid" />;
        case TicketState.APPROVED:
            return (
                <Localized
                    de="Freigegeben"
                    fr="Approuvé"
                    it="Approvato"
                    en="Approved"
                />
            );
        default:
            return (
                <Localized
                    de="Zahlungsfreigabe fehlt"
                    fr="Approbation du paiement manquante"
                    it="Approvazione pagamento mancante"
                    en="Payment release missing"
                />
            );
    }
}

function PendingTicketPriceDisplay({
    state,
}: {
    state?: TicketState;
}): JSX.Element {
    switch (state) {
        case TicketState.APPROVED:
            return (
                <>
                    <PendingRounded color={'success'} />
                    <TranslationForTicketState state={state} />
                </>
            );
        case TicketState.ALIAS_PENDING:
            return (
                <>
                    <ErrorRounded color={'warning'} />
                    <TranslationForTicketState state={state} />
                </>
            );
        default:
            return <>{EmDash}</>;
    }
}

function RecodeBlockedForCompleteTransactions() {
    return (
        <Localized
            de="Ein Rabatt kann nur auf laufende Transaktionen angewendet werden"
            fr="Un rabais ne peut être appliquée que sur les transactions en cours"
            it="Un ribasso può essere applicato solo alle transazioni in corso"
            en="Discount can only be applied to ongoing parking transactions"
        />
    );
}

function RunningTicketTooltip({
    recodePermission,
    recodeError,
}: {
    recodePermission: boolean;
    recodeError: RecodeErrorType | null;
}) {
    if (!recodePermission) {
        return <MissingPermission />;
    }
    if (recodeError != null) {
        return <CloudConnectorRecodeErrorText type={recodeError} />;
    }
    return null;
}

function MissingPermission() {
    return (
        <Localized
            de="Funktion nicht verfügbar: Ihnen fehlen die erforderlichen Berechtigungen"
            fr="Fonction non disponible: vous ne disposez pas des droits nécessaires"
            it="Funzione non disponibile: non dispone dei diritti necessari"
            en="Function not available: you don't have the necessary permissions"
        />
    );
}
