import { GeoJsonObject, MultiPoint, Point } from 'geojson';
import * as L from 'leaflet';

import { CircleMarker, FeatureGroup, GeoJSON } from 'react-leaflet';
import { stringify } from 'wellknown';

const POINT_CIRCLE_RADIUS = 5;

export enum Color {
    MARKER = '#B40404',
    MARKER_HOVER = '#027200',
    CHILDREN_MARKER = '#2730B1',
    REFERENCE_MARKER = 'yellow',
}

export function Geo({
    geoJson,
    color,
    hoverColor,
    onClick,
    children,
}: {
    geoJson: GeoJsonObject;
    color: Color;
    hoverColor?: Color;
    onClick?: () => void;
    tooltip?: React.ReactNode;
    children?: React.ReactNode;
}) {
    const setHoverCallback =
        hoverColor && hoverColor !== color
            ? (event: L.LeafletMouseEvent) => {
                  if (event.type === 'mouseover') {
                      event.target.setStyle({ color: hoverColor });
                  }
                  if (event.type === 'mouseout') {
                      event.target.setStyle({ color: color });
                  }
              }
            : () => {};

    if (geoJsonIsPoint(geoJson)) {
        return (
            <CircleMarker
                center={geoJson.coordinates as [number, number]}
                radius={POINT_CIRCLE_RADIUS}
                pathOptions={{ color: color, weight: 2 }}
                eventHandlers={{
                    click: onClick || (() => {}),
                    mouseover: setHoverCallback,
                    mouseout: setHoverCallback,
                }}
            >
                {children}
            </CircleMarker>
        );
    }

    if (isMultiPoint(geoJson)) {
        return (
            <FeatureGroup
                eventHandlers={{
                    mouseover: setHoverCallback,
                    mouseout: setHoverCallback,
                }}
            >
                {geoJson.coordinates.map((c, i) => (
                    <CircleMarker
                        key={i}
                        center={[c[1], c[0]]}
                        radius={POINT_CIRCLE_RADIUS}
                        pathOptions={{ color: color, weight: 2 }}
                        eventHandlers={{
                            click: onClick || (() => {}),
                        }}
                    >
                        {children}
                    </CircleMarker>
                ))}
            </FeatureGroup>
        );
    }

    // we need to re-generate the layer if the geoJson property changes, thus the key=...
    return (
        <GeoJSON
            key={hashCode(stringify(geoJson))}
            data={geoJson}
            pathOptions={{ color: color, weight: 2 }}
            onEachFeature={(_feature, layer) =>
                layer.on({
                    mouseover: setHoverCallback,
                    mouseout: setHoverCallback,
                    click: onClick || (() => {}),
                })
            }
        >
            {children}
        </GeoJSON>
    );
}

export const hashCode = function (value: string): number {
    let hash = 0,
        i,
        chr;
    if (value.length === 0) {
        return hash;
    }
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        /* tslint:disable:no-bitwise */
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export function geoJsonIsPoint(geo: GeoJsonObject | null): geo is Point {
    return geo != null && geo.type === 'Point';
}

export function isMultiPoint(geo: GeoJsonObject | null): geo is MultiPoint {
    return geo != null && geo.type === 'MultiPoint';
}
