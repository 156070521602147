import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { OverridableComponent } from '@mui/material/OverridableComponent';

export function MaterialTitle({
    Icon,
    title,
}: {
    Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
    };
    title: React.ReactNode;
}) {
    const theme = useTheme();

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Icon
                style={{
                    fontSize: 42,
                    color: theme.palette.blue.main,
                    marginRight: theme.spacing(2),
                }}
            />
            <Typography variant="h3" component="h1">
                {title}
            </Typography>
        </Box>
    );
}
